<template>
  <form
    class="form"
    v-bind="$attrs"
  >
    <slot name="header" />
    <slot />
    <slot name="footer" />
  </form>
</template>

<script>
export default {
  name: 'VForm',
};
</script>
