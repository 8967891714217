let searchRequestCounter = 0;
const highlightedGenres = ["all"];

export default async function doSearch(searchText) {
  if (searchText.length < 3) return [];
  // костыль чтобы запросы не пересекались когда быстро вводишь буквы
  searchRequestCounter++;
  const joinedFilters = highlightedGenres.join(",");

  const options = {
    'search_text': searchText,
    'req_id': searchRequestCounter,
    'search_filter':joinedFilters,
  };

  const { contentQueryParams } = storeToRefs(useUserStore());
  const { search_results, req_id } = await useApi(
    '/search/',
    { query: { ...contentQueryParams.value, ...options } },
  );

  // выход из костыля:
  // если за время выполнения запроса 1 прилетел результат запроса 2, то когда прилетит запрос 1, он проигнорируется
  if (searchRequestCounter > parseInt(req_id)) {
    // console.log("Ignore search display cuz " + searchRequestCounter + " > " + req_id);
    return;
  }

  return search_results || [];
}
