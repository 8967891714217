<template>
  <div class="v-switcher">
    <label class="v-switcher__wrapper">
      <span
        if="label"
        class="v-switcher__label"
      >{{ label }}</span>
      <input
        v-model="localChecked"
        type="checkbox"
        class="v-switcher__input"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'VSwitch',
  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:checked'],
  computed: {
    localChecked: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('update:checked', value);
      },
    },
  },
};
</script>

<style lang="scss">
.v-switcher {
  width: fit-content;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    cursor: pointer;
    font-size: 12px;
    margin-right: 4px;
    color: get-theme-for($text, 'primary');
    white-space: nowrap;
  }

  &__input {
    position: relative;
    width: 36px;
    height: 20px;
    margin: 0;
    background: $--black-100;
    vertical-align: top;
    border-radius: 16px;
    outline: none;
    cursor: pointer;
    appearance: none;
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 16px;
      height: 16px;
      background-color: $--white;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(17, 17, 17, 0.02);
      transform: translateX(0);
      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:checked {
      background-color: get-theme-for($text, 'success');
    }

    &:checked::after {
      transform: translateX(100%);
      background-color: $--white;
      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
  }
}
</style>
