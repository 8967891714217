<template>
  <button
    v-if="hasNotPopover"
    class="empty-card"
    :class="cardClass"
  >
    <v-icon
      name="plus"
      size="32"
      color="#364A58"
    />
  </button>
  <v-popover
    v-else
    :placement="mediaQueries.phone ? 'bottom' : 'left'"
    auto-boundary-max-size
    :prevent-overflow="mediaQueries.phone ? 'auto' : true"
    :overflow-padding="!mediaQueries.phone && 60"
    :triggers="['click']"
    :distance="distance"
    :skidding="skidding"
    auto-hide
  >
    <template #default>
      <button
        class="empty-card"
        :class="cardClass"
      >
        <v-icon
          name="plus"
          size="32"
          color="#364A58"
        />
      </button>
    </template>
    <template #popper>
      <slot name="popper">
        <div class="popover-content">
          <div class="popover-content__icon-wrapper">
            <v-icon
              name="alertCircle"
              size="20"
            />
          </div>
          <p class="popover-content__text">
            Для подключения тематических подписок и дополнительных каналов вы можете обратиться к своему провайдеру
          </p>
        </div>
      </slot>
    </template>
  </v-popover>
</template>

<script>
import VIcon from '@/components/common/VIcon.vue';
import VPopover from '@/components/common/VPopover.vue';

export default {
  name: 'EmptyCard',
  components: { VIcon, VPopover },
  props: {
    fullWidth: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
    compressed: { type: Boolean, default: false },
    tiny: { type: Boolean, default: false },
    hasNotPopover: { type: Boolean, default: false },
  },
  setup() {
    const mediaQueries = useMediaQueries();
    return {mediaQueries};
  },
  computed: {
    cardClass() {
      return {
        'empty-card--fit': this.fit,
        'empty-card--compressed': this.compressed,
        'empty-card--tiny': this.tiny,
        'empty-card--full-width': this.fullWidth,
      };
    },
    distance() {
      return this.compressed && !this.mediaQueries.phone ? -98 : 0;
    },
    skidding() {
      return this.compressed && !this.mediaQueries.phone ? -140 : 0;
    },
  },
};
</script>

<style lang="scss">
.popover-content {
  display: flex;
  font-size: $--font-size-larger;

  &__icon-wrapper {
    margin-right: 8px;
  }
}

.empty-card {
  width: 168px;
  height: 360px;
  border-radius: $--border-radius-large;
  border: solid 1px $--black-100;
  transition: $--transition-duration;
  svg {
      color: $--light-gray;
      path {
        fill: $--light-gray;
      }
    }
  &:hover {
    border-color: $--light-gray;
  }

  @include flex-centered;

  @include tablet {
    width: 149px;
    height: 325px;
  }

  @include phone {
    width: 135px;
    height: 256px;
  }

  &--fit {
    width: 160px;
    height: 342px;

    @include tablet {
      width: 130px;
      height: 273px;
    }

    @include phone {
      width: 135px;
      height: 256px;
    }
    svg {
      color: $--light-gray;
      path {
        fill: $--light-gray;
      }
    }
  }

  &--tiny {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    svg {
      color: $--light-gray;
      path {
        fill: $--light-gray;
      }
    }
  }

  &--compressed {
    width: 226px;
    height: 80px;
    border-radius: $--border-radius-large;
    border: solid 1px $--black-100;

    @include flex-centered;

    @include tablet {
      width: 197px;
    }

    @include phone {
      width: 262px;
    }
  }

  &--full-width {
    width: 100%;
  }
}
</style>
