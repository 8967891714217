<template>
  <div
    class="timer"
    v-bind="$attrs"
  >
    <slot
      name="timer"
      :current-time="currentTime"
      :reset-timer="resetTimer"
    />
  </div>
</template>

<script>
export default {
  name: 'VTimer',
  props: {
    time: {
      type: Number,
      default: 60,
    },
  },
  emits: ['time-end'],

  data() {
    return {
      currentTime: this.time,
      timer: null,
    };
  },

  mounted() {
    this.startTimer();
  },

  beforeUnmount() {
    this.stopTimer();
  },

  methods: {
    stopTimer() {
      clearInterval(this.timer);
    },
    resetTimer() {
      this.currentTime = this.time;
      this.startTimer();
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1;
        if (this.currentTime === 0) {
          this.stopTimer();
          this.$emit('time-end');
        }
      }, 1000);
    },
  },
};
</script>
