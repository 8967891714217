import { default as _91name_935tSRA8KxVTMeta } from "/opt/web_client/pages/actor/[name].vue?macro=true";
import { default as _91_46_46_46arg_932SzDcoi5AyMeta } from "/opt/web_client/pages/channel/[...arg].vue?macro=true";
import { default as indexKAezuw5Ju2Meta } from "/opt/web_client/pages/channel/index.vue?macro=true";
import { default as _91name_93lgQ0hydMLeMeta } from "/opt/web_client/pages/cinema/[name].vue?macro=true";
import { default as _91name_93TifRGVxQjmMeta } from "/opt/web_client/pages/cinema/collection/[name].vue?macro=true";
import { default as indexMDNM4OD1nJMeta } from "/opt/web_client/pages/index.vue?macro=true";
import { default as indexylxCInL5POMeta } from "/opt/web_client/pages/kino/index.vue?macro=true";
import { default as logout95FPNHDzfOMeta } from "/opt/web_client/pages/logout.vue?macro=true";
import { default as indexbI7OVNzHZnMeta } from "/opt/web_client/pages/personal-account/index.vue?macro=true";
import { default as indexl0gMXaL4AmMeta } from "/opt/web_client/pages/questions/index.vue?macro=true";
import { default as _91name_93ykzJ08fP7uMeta } from "/opt/web_client/pages/search/[name].vue?macro=true";
import { default as _91id_930aeSOzVWESMeta } from "/opt/web_client/pages/subscriptions/detail/[id].vue?macro=true";
import { default as indexl8Qhq8LtT0Meta } from "/opt/web_client/pages/subscriptions/index.vue?macro=true";
import { default as indextxBlmgtRnjMeta } from "/opt/web_client/pages/tv-player/index.vue?macro=true";
import { default as _91name_93NrOotNKjUSMeta } from "/opt/web_client/pages/vod/[name].vue?macro=true";
export default [
  {
    name: _91name_935tSRA8KxVTMeta?.name ?? "actor-name",
    path: _91name_935tSRA8KxVTMeta?.path ?? "/actor/:name()",
    meta: _91name_935tSRA8KxVTMeta || {},
    alias: _91name_935tSRA8KxVTMeta?.alias || [],
    redirect: _91name_935tSRA8KxVTMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/actor/[name].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46arg_932SzDcoi5AyMeta?.name ?? "channel-arg",
    path: _91_46_46_46arg_932SzDcoi5AyMeta?.path ?? "/channel/:arg(.*)*",
    meta: _91_46_46_46arg_932SzDcoi5AyMeta || {},
    alias: _91_46_46_46arg_932SzDcoi5AyMeta?.alias || [],
    redirect: _91_46_46_46arg_932SzDcoi5AyMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/channel/[...arg].vue").then(m => m.default || m)
  },
  {
    name: indexKAezuw5Ju2Meta?.name ?? "channel",
    path: indexKAezuw5Ju2Meta?.path ?? "/channel",
    meta: indexKAezuw5Ju2Meta || {},
    alias: indexKAezuw5Ju2Meta?.alias || [],
    redirect: indexKAezuw5Ju2Meta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/channel/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93lgQ0hydMLeMeta?.name ?? "cinema-name",
    path: _91name_93lgQ0hydMLeMeta?.path ?? "/cinema/:name()",
    meta: _91name_93lgQ0hydMLeMeta || {},
    alias: _91name_93lgQ0hydMLeMeta?.alias || [],
    redirect: _91name_93lgQ0hydMLeMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/cinema/[name].vue").then(m => m.default || m)
  },
  {
    name: _91name_93TifRGVxQjmMeta?.name ?? "cinema-collection-name",
    path: _91name_93TifRGVxQjmMeta?.path ?? "/cinema/collection/:name()",
    meta: _91name_93TifRGVxQjmMeta || {},
    alias: _91name_93TifRGVxQjmMeta?.alias || [],
    redirect: _91name_93TifRGVxQjmMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/cinema/collection/[name].vue").then(m => m.default || m)
  },
  {
    name: indexMDNM4OD1nJMeta?.name ?? "index",
    path: indexMDNM4OD1nJMeta?.path ?? "/",
    meta: indexMDNM4OD1nJMeta || {},
    alias: indexMDNM4OD1nJMeta?.alias || [],
    redirect: indexMDNM4OD1nJMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexylxCInL5POMeta?.name ?? "kino",
    path: indexylxCInL5POMeta?.path ?? "/kino",
    meta: indexylxCInL5POMeta || {},
    alias: indexylxCInL5POMeta?.alias || [],
    redirect: indexylxCInL5POMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/kino/index.vue").then(m => m.default || m)
  },
  {
    name: logout95FPNHDzfOMeta?.name ?? "logout",
    path: logout95FPNHDzfOMeta?.path ?? "/logout",
    meta: logout95FPNHDzfOMeta || {},
    alias: logout95FPNHDzfOMeta?.alias || [],
    redirect: logout95FPNHDzfOMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: indexbI7OVNzHZnMeta?.name ?? "personal-account",
    path: indexbI7OVNzHZnMeta?.path ?? "/personal-account",
    meta: indexbI7OVNzHZnMeta || {},
    alias: indexbI7OVNzHZnMeta?.alias || [],
    redirect: indexbI7OVNzHZnMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/personal-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexl0gMXaL4AmMeta?.name ?? "questions",
    path: indexl0gMXaL4AmMeta?.path ?? "/questions",
    meta: indexl0gMXaL4AmMeta || {},
    alias: indexl0gMXaL4AmMeta?.alias || [],
    redirect: indexl0gMXaL4AmMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93ykzJ08fP7uMeta?.name ?? "search-name",
    path: _91name_93ykzJ08fP7uMeta?.path ?? "/search/:name()",
    meta: _91name_93ykzJ08fP7uMeta || {},
    alias: _91name_93ykzJ08fP7uMeta?.alias || [],
    redirect: _91name_93ykzJ08fP7uMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/search/[name].vue").then(m => m.default || m)
  },
  {
    name: _91id_930aeSOzVWESMeta?.name ?? "subscriptions-detail-id",
    path: _91id_930aeSOzVWESMeta?.path ?? "/subscriptions/detail/:id()",
    meta: _91id_930aeSOzVWESMeta || {},
    alias: _91id_930aeSOzVWESMeta?.alias || [],
    redirect: _91id_930aeSOzVWESMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/subscriptions/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexl8Qhq8LtT0Meta?.name ?? "subscriptions",
    path: indexl8Qhq8LtT0Meta?.path ?? "/subscriptions",
    meta: indexl8Qhq8LtT0Meta || {},
    alias: indexl8Qhq8LtT0Meta?.alias || [],
    redirect: indexl8Qhq8LtT0Meta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: indextxBlmgtRnjMeta?.name ?? "tv-player",
    path: indextxBlmgtRnjMeta?.path ?? "/tv-player",
    meta: indextxBlmgtRnjMeta || {},
    alias: indextxBlmgtRnjMeta?.alias || [],
    redirect: indextxBlmgtRnjMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/tv-player/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93NrOotNKjUSMeta?.name ?? "vod-name",
    path: _91name_93NrOotNKjUSMeta?.path ?? "/vod/:name()",
    meta: _91name_93NrOotNKjUSMeta || {},
    alias: _91name_93NrOotNKjUSMeta?.alias || [],
    redirect: _91name_93NrOotNKjUSMeta?.redirect || undefined,
    component: () => import("/opt/web_client/pages/vod/[name].vue").then(m => m.default || m)
  }
]