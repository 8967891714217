import { toNumber } from '@/utils/typehint';

export const usePausedListStore = defineStore('pausedList', () => {
  const { data, fetch: fetchPausedList } = useApiData('paused_media/get', {
    default: () => [],
    transform: d => {
      return d.data.map(p => {
        p.pause_time = toNumber(p.pause_time);
        return p;
      });
    },
  });
  const pausedList = computed(() => data.value || []);

  function vodInPaused(vodId) {
    for (const pausedVod of pausedList.value) {
      if (pausedVod.film_id === vodId) return pausedVod.pause_time;
    }
    return null;
  }

  async function addToPause(options = {}) {
    await useApi('paused_media/add', { query: options });
    fetchPausedList(true);
  }

  async function deleteFromPause(options = {}) {
    await useApi('paused_media/delete', { query: options });
    fetchPausedList(true);
  }

  return {
    pausedList,
    //
    fetchPausedList,
    vodInPaused,
    addToPause,
    deleteFromPause,
  };
});
