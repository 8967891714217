<template>
  <Popper
    ref="popper"
    v-slot="{
      popperId,
      isShown,
      shouldMountContent,
      skipTransition,
      autoHide,
      hide,
      handleResize,
      onResize,
      classes,
      result,
    }"
    :triggers="['hover', 'focus']"
    placement="left"
    :distance="-84"
    :skidding="-50"
    v-bind="$attrs"
    :theme="theme"
    :target-nodes="getTargetNodes"
    :reference-node="() => $refs.reference"
    :popper-node="() => $refs.popperContent.$el"
  >
    <div
      ref="reference"
      class="v-popper"
      :class="[
        {
          'v-popper--shown': isShown,
        },
      ]"
    >
      <slot />

      <popper-content
        ref="popperContent"
        :popper-id="popperId"
        :theme="theme"
        :shown="isShown"
        :mounted="shouldMountContent"
        :skip-transition="skipTransition"
        :auto-hide="autoHide"
        :handle-resize="handleResize"
        :classes="classes"
        :result="result"
        @hide="hide"
        @resize="onResize"
      >
        <slot
          name="popper"
          :shown="isShown"
        />
      </popper-content>
    </div>
  </Popper>
</template>

<script>
import Popper from 'floating-vue/src/components/Popper';
import PopperContent from 'floating-vue/src/components/PopperContent';
import PopperMethods from 'floating-vue/src/components/PopperMethods';
import ThemeClass from 'floating-vue/src/components/ThemeClass';

export default {
  name: 'VPopperWrapper',
  components: {
    Popper: Popper(),
    PopperContent,
  },
  mixins: [PopperMethods, ThemeClass],
  inheritAttrs: false,
  props: {
    theme: {
      type: String,
      default: 'base-tooltip',
    },
  },
  methods: {
    getTargetNodes() {
      return Array.from(this.$refs.reference.children);
    },
  },
};
</script>
