<template>
  <v-card class="v-auth-dialog">
    <v-card-header class="v-auth-dialog__header">
      <template #title> Авторизация </template>
      <template #subtitle> Для дальнейшей работы войдите в личный кабинет </template>
    </v-card-header>
    <validation-observer
      v-slot="{ errors, handleSubmit }"
      as="div"
    >
      <!-- <v-form class="v-auth-dialog__form" @submit.prevent="handleSubmit(onSubmit)"> -->
      <v-form
        class="v-auth-dialog__form"
        @submit.prevent="handleSubmit($event, onSubmit)"
      >
        <template #default>
          <div class="v-auth-dialog__form-main">
            <v-form-row>
              <v-form-field
                label="Логин"
                class="v-auth-dialog__form-field"
                :error-message="errors.login"
              >
                <v-input
                  v-model="user.login"
                  placeholder="Введите логин"
                  type="text"
                  :is-error="errors.login"
                  name="login"
                  rules="required"
                />
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field
                label="Пароль"
                class="v-auth-dialog__form-field"
                :error-message="errors.password"
              >
                <v-input
                  v-model="user.password"
                  type="password"
                  placeholder="Введите пароль"
                  :is-error="errors.password"
                  name="password"
                  rules="required"
                />
              </v-form-field>
            </v-form-row>
            <div class="v-auth-dialog__form-help">
              <v-button
                class="v-auth-dialog__form-help-button"
                is-text
                @click="toggleWhereSendLoginPasswordDialog(true)"
              >
                Как получить логин и пароль?
              </v-button>
            </div>
          </div>
        </template>
        <template #footer>
          <slot name="actions" />
        </template>
      </v-form>
    </validation-observer>
  </v-card>
  <v-registration-dialog v-model:visible="showRegistrationDialog" />
  <v-where-find-login-password v-model:visible="showWhereSendLoginPasswordDialog" />
</template>

<script setup>
import { ref, reactive } from 'vue';
import VCard from '@/components/common/VCard.vue';
import VCardHeader from '@/components/common/VCardHeader.vue';
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VInput from '@/components/common/VInput.vue';
import VButton from '@/components/common/VButton.vue';
import VRegistrationDialog from '@/components/common/VAuthDialog.vue';
import VWhereFindLoginPassword from '@/components/Registration/WhereFindLoginPasswordDialog.vue';
import { Form as ValidationObserver } from 'vee-validate';
// import { required, email, confirmed, min_value as minValue, min, between } from '@vee-validate/rules'
// import { AbilityBuilder, Ability } from '@casl/ability'
// import { ABILITY_TOKEN } from '@casl/vue'
// import authService from '@/services/auth'
import { alertService } from '@/services/alert';
import { doLogin } from '@/composables/useApi';
import { getExpireDate } from '@/utils/util';
import { encrypt } from '@/utils/util';
import { SKEY } from '@/constants/common';

const emits = defineEmits(['auth-success']);
const showRegistrationDialog = ref(false);
const showWhereSendLoginPasswordDialog = ref(false);
const rc = useRuntimeConfig().public;

const toggleWhereSendLoginPasswordDialog = value => {
  showWhereSendLoginPasswordDialog.value = value;
};

const user = reactive({ login: null, password: null });

const onSubmit = async () => {
  const { login, password } = user;

  const encryptedLogin = encrypt(login, SKEY);
  const encryptedPassword = encrypt(password, SKEY);

  let expiryDate = getExpireDate(60);

  useCookie('login', { expires: expiryDate }).value = encryptedLogin;
  useCookie('password', { expires: expiryDate }).value = encryptedPassword;

  await nextTick();
  await doLogin();
  emits('auth-success');
  alertService.success({ text: 'Авторизация прошла успешно', isIconCross: true, timeout: 4500 });
};
</script>

<style lang="scss">
.v-auth-dialog {
  max-width: 560px;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;

  @include phone {
    padding: 44px 16px 35px;
  }

  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--light-gray-300;
    font-size: $--font-size-subtitle-phone;

    @include phone {
      font-size: $--font-size-small;
    }
  }

  &__form {
    &-main {
      margin-bottom: 34px;

      @include phone {
        margin-bottom: 32px;
      }
    }

    &-field {
      &:last-child {
        margin-bottom: 20px;

        @include phone {
          margin-bottom: 16px;
        }
      }
    }

    &-help {
      text-align: center;

      &-button {
        font-size: 16px;
        text-decoration: underline;
        color: get-theme-for($text, 'secondary');
      }
    }

    &-buttons-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;

      @include phone {
        padding: 0;
        row-gap: 8px;
      }
    }

    &-button {
      min-width: 336px;

      &:last-child {
        color: $--light-blue;
        font-weight: $--font-weight-regular;
        font-size: $--font-size-button;
      }

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
        min-width: unset;
        width: 100%;
      }
    }
  }
}
</style>
