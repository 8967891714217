<template>
  <div class="form-field">
    <template v-if="separateLabel">
      <label>
        <span
          v-if="label"
          :class="classes"
        >{{ label }}</span>
      </label>
      <slot />
    </template>
    <template v-else>
      <label>
        <span
          v-if="label"
          :class="classes"
        >{{ label }}</span>
        <slot />
      </label>
    </template>

    <slot
      v-if="!hideErrors"
      name="error"
      :error-message="errorMessage"
    >
      <v-form-error>
        {{ errorMessage }}
      </v-form-error>
    </slot>
  </div>
</template>

<script>
import VFormError from '@/components/form/VFormError.vue';

export default {
  name: 'VFormField',
  components: { VFormError },
  props: {
    label: {
      type: String,
      default: '',
    },
    labelSize: {
      type: String,
      default: 'larger',
      validator: size => ['larger'].includes(size),
    },
    separateLabel: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return ['form-field__title', `form-field__title--${this.labelSize}`];
    },
  },
};
</script>

<style lang="scss">
.form-field__title {
  margin-bottom: 4px;
  display: block;

  &--larger {
    color: get-theme-for($text, 'secondary');
    font-size: $--font-size-larger;

    @include phone {
      font-size: $--font-size-base;
    }
  }
}
</style>
