export const useCollectionsListStore = defineStore('collectionsList', () => {
  const { vodUrl } = storeToRefs(useSettingsStore());
  const { data: collectionsList, fetch: fetchCollections } = useApiData(
    'collections',
    {
      watch: vodUrl,
      default: () => [],
      transform: d => (Array.isArray(d.collections) ? d.collections : []),
    },
    vodUrl
  );

  return {
    collectionsList,
    fetchCollections,
  };
});
