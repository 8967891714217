export const useVendorStore = defineStore('vendorList', () => {
  const settingsStore = useSettingsStore();
  // const { vodUrl, settings: settingsStored } = storeToRefs(settingsStore);
  const { vodUrl, settings } = storeToRefs(settingsStore);
  const { customerInfo } = storeToRefs(useCustomerInfoStore());
  const { user } = storeToRefs(useUserStore());

  const currentVendorName = shallowRef('start');
  const path = computed(() => `genres/${currentVendorName.value}`);
  const {
    data,
    fetch: fetchVendor,
    clean,
  } = useApiData(
    path,
    {
      transform: d => d.genres || [],
      watch: [currentVendorName, vodUrl, path],
    },
    vodUrl,
  );

  const cinemasFromApi = computed(() => {
    return (settings.value?.newMainMenu || [])
      .filter(i => i.service === 'cinema')
      .map(v => {
        if (!v.color) {
          v.color = '#000';
        }
        if(!v.summary) {
          v.summary = 'Онлайн-кинотеатр с любовью к российской уникальности.<br>Если вы ищете сериалы, фильмы и шоу с острым и честным взглядом на жизнь, то вам сюда.';
        }
        return v;
      });
  });
  const cinemaList = computed(() => {
    const uservalue = user.value || {};
    if (customerInfo.account_type !== 'ur') {
      return cinemasFromApi.value;
    }
    // отображение кинотеатров, не входящих в подписку - всегда скрыто
    return cinemasFromApi.value.filter(cinema =>
      uservalue.tariff_options.split(',').some(t => t.startsWith(cinema.key)),
    );
  });
  const currentVendor = computed(() => cinemaList.value.find(cinema => cinema.key === currentVendorName.value) || {});
  const vendorList = computed(() => data.value || []);

  // function cinemaColor(vendor) {
  //   return cinemasFromApi.find(cinema => cinema.key === vendor)?.color;
  // }

  return {
    currentVendorName,
    currentVendor,
    vendorList,
    cinemasFromApi,
    cinemaList,
    //
    fetchVendor,
    clean,
    // cinemaColor,
  };
});
