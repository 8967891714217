import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { updateMacToUuid } from '@/utils/util';

export const useUserStore = defineStore('user', () => {
  const user = shallowRef({});
  const status = shallowRef('unauthenticated');
  const { customerInfo } = storeToRefs(useCustomerInfoStore());
  const { data: defaultUser, fetch: fetchDefaultUser } = useApiData('/api/user', {}, false);
  const authState = useState('authkey', () => useCookie('authkey').value);

  /**
   * @deprecated
   * all required params set in useApi
   */
  const requiredParams = shallowRef({});
  const isAuthenticated = computed(() => {
    // Создаем uuid для Mac если его нет или он равен по умолчанию 123
    if (!!authState.value) {
      updateMacToUuid();
    }

    return !!authState.value;
  });
  const contentQueryParams = computed(() => {
    const query = {};
    if (isAuthenticated.value) {
      const profile = user.value.current_profile || {};
      query.age_rating = profile.age_rating;
      // Условия показа контента
      query.full = customerInfo.value.account_type === 'fiz' ? profile.show_unsubscribed : '0';
    }
    return query;
  });

  /**
   * @deprecated
   * user ref is watching auth session
   */
  function setUser(data) {
    user.value = data;
  }

  function changeCurrentProfile(current_profile) {
    user.value = { ...user.value, current_profile };
    setHideSetPin();
  }

  /**
   * Обновляет локальный объект текущего профиля
   * @param {Object} data 
   * @returns Boolean 
   */
  function updateLocalCurrentProfile(data) {
    if (data.id !== user.value.current_profile.id) {
      return false;

    }
    user.value = { ...user.value, current_profile: { ...user.value.current_profile, ...data } };
    return true;
  }

  /**
   * Сохранить пользователя в localStorage
   * @returns 
   */
  function saveUserToLocalStorage() {
    if (!Object.keys(user.value).length) {
      localStorage.removeItem('user');
      return;
    }
    localStorage.setItem('user', JSON.stringify(user.value));
  }

  function setHideSetPin() {
    const newValue = localStorage.getItem('hide_set_pin') || false;
    try {
      user.value = {
        ...user.value,
        current_profile: {
          ...user.value.current_profile,
          hide_set_pin: JSON.parse(newValue),
        },
      };
    } catch { }
  }

  /**
   * @deprecated
   */
  const requiredParamsToString = computed(() => {
    let requiredParamsString = '';
    const requiredParamsArray = Object.entries(requiredParams.value);

    requiredParamsArray.forEach(([key, value]) => {
      requiredParamsString = `${requiredParamsString}&${key}=${value}`;
    });

    return requiredParamsString;
  });

  return {
    user,
    status,
    authState,
    isAuthenticated,
    contentQueryParams,
    defaultUser,
    requiredParams,
    requiredParamsToString,
    //
    setUser,
    changeCurrentProfile,
    fetchDefaultUser,
    updateLocalCurrentProfile,
    saveUserToLocalStorage,
  };
});
