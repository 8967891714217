<template>
  <header class="v-card-header">
    <slot name="custom-title" />
    <h1 class="v-card-header__title">
      <slot name="title" />
    </h1>

    <slot name="custom-subtitle" />
    <p class="v-card-header__subtitle">
      <slot name="subtitle" />
    </p>
  </header>
</template>

<script>
export default {
  name: 'VCardHeader',
};
</script>

<style lang="scss">
.v-card-header {
  text-align: center;

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;
    color: $--white;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      font-weight: $--font-weight-medium;
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    color: $--light-gray-300;
    font-size: $--font-size-subtitle-phone;
    line-height: 32px;

    @include phone {
      font-size: $--font-size-small;
      line-height: 16px;
    }
  }
}
</style>
