export const useFavoritesListStore = defineStore('favoritesStore', () => {
  const { data, fetch: fetchFavorites } = useApiData('favorites/get');
  const favoritesList = computed(() => data.value?.data || []);

  const typeActions = {
    'program': {
      query: programInfo => ({ channel_id: programInfo?.channel_id, program_time: programInfo?.time_start }),
    },
    'channel': {
      query: programInfo => ({ channel_id: programInfo?.channel_id }),
    },
    'film': {
      query: programInfo => ({ film_id: programInfo?.id }),
    },
  };

  const messages = {
    'program': {
      add: 'Телепередача добавлена в "Избранное"',
      del: 'Телепередача удалена из "Избранного"',
    },
    'film': {
      add: 'Видео добавлено в "Избранное"',
      del: 'Видео удалено из "Избранного"',
    },
    'channel': {
      add: 'Канал добавлен в "Мои телеканалы"',
      del: 'Канал удален из "Мои телеканалы"',
    },
  };

  async function addToFavorites(options = {}) {
    await useApi('favorites/add', { query: options, contentQueryParams: false });
    fetchFavorites(true);
  }

  async function deleteFromFavorites(options = {}) {
    await useApi('favorites/delete', { query: options });
    fetchFavorites(true);
  }

  /**
   * Определить является ли переданный элемент избранным 
   * @param {Object} programInfo - информация о передаваемом канале, программе или фильме
   * @param {String|Array} type - тип элемента (program, channel, film)
   * @returns Booelan
   */
  function isFavorite(programInfo, type) {
    if (!programInfo || !favoritesList.value.length) {      
      return false;
    }
    // Если type является строкой, обернем его в массив
    const types = Array.isArray(type) ? type : [type];

    const checkFavorite = t => {
      const typeCheckers = {
        'program': f => f.channel_id === programInfo.channel_id && f.time_start === programInfo.time_start,
        'channel': f => f.channel_id === programInfo.channel_id,
        'film': f => f.film_id === programInfo.id,
      };

      return favoritesList.value.some(f => f.fav_type === t && typeCheckers[t](f));
    };

    return types.some(checkFavorite);
  }

  /**
   * Добавить или удалить элемент из избранного
   * @param {Object} programInfo 
   * @param {String} type 
   * @param {Boolean} isFav 
   * @returns String
   */
  async function toggleFavorite(programInfo, type, isFav) {
    const { query } = typeActions[type];
    const { add, del } = messages[type];

    if (isFav) {
      await deleteFromFavorites(query(programInfo));
      return del;
    } else {
      await addToFavorites(query(programInfo));
      return add;
    }
  }

  return {
    favoritesList,
    //
    fetchFavorites,
    addToFavorites,
    deleteFromFavorites,
    isFavorite,
    toggleFavorite,
  };
});
