export const useRecommendedListStore = defineStore('recommendedStore', () => {
  const { data, fetch: fetchRecommended } = useApiData('recommendations/get', {
    query: { count: 20 },
    transform: d => d?.recommendations || [],
  });

  return {
    recommendedList: data,
    // recommendedList,
    //
    fetchRecommended,
  };
});
