import { reactive } from 'vue';

function createCounter() {
  let count = 0;
  return () => {
    count += 1;
    return count;
  };
}

const nextCount = createCounter();

export const alerts = reactive({
  list: [],
});

export const alertService = {
  list: alerts.list,

  push({ type, text, timeout, isIconCross }) {
    if (!alerts.list?.length) {
      alerts.list.push({ type, text, id: nextCount(), timeout, isIconCross });
    }
  },
  pop(id) {
    alerts.list = alerts.list.filter(item => item.id !== id);
  },

  success({ text, timeout, isIconCross }) {
    this.push({ type: 'success', text, timeout, isIconCross });
  },
  error({ text, timeout, isIconCross }) {
    this.push({ type: 'error', text, isIconCross, timeout });
  },
  warning({ text, timeout, isIconCross }) {
    this.push({ type: 'warning', text, timeout, isIconCross });
  },
  info({ text, timeout, isIconCross }) {
    this.push({ type: 'info', text, timeout, isIconCross });
  },

  shift() {
    this.list.pop();
  },
};
