<template>
  <div :class="['v-input', classes]">
    <div
      v-if="$slots.prepend"
      class="v-input__prepend"
    >
      <slot name="prepend" />
    </div>
    <input
      v-bind="$attrs"
      :id="name"
      ref="input"
      :type="inputType"
      :value="localValue"
      :placeholder="placeholder"
      class="v-input__element"
      :disabled="disabled"
      @input="handleInput($event.target.value)"
      @focus="isFocused = true"
      @blur="isFocused = false"  
    >
    <div
      v-if="$slots.append"
      class="v-input__append"
    >
      <slot name="append" />
    </div>
    <button
      v-if="type === 'password'"
      type="button"
      class="v-input__button-switch"
      @click="togglePassword"
    >
      {{ textOfSwitch }}
    </button>
  </div>
</template>

<script>
// import veeValidate from '@/plugins/veeValidate'
import { useField } from 'vee-validate';
import createTextMaskInputElement from 'text-mask-core/src/createTextMaskInputElement';
import { TEXT_MASK_CONFIG_DEFAULT, TEXT_MASK_AVAILABLE_INPUT_TYPES } from '@/constants/textMask';

export default {
  name: 'VInput',

  inheritAttrs: false,

  props: {
    size: { type: String, default: '' },
    filled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    isError: { type: Boolean, default: false },
    modelValue: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },
    mask: { type: Array, default: null },
    textMaskConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    name: { type: String, required: true },
    rules: { type: [Function, String, Object, Array], default: '' },
    placeholder: { type: String, default: '' },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      textMask: null,
      localValue: '',
      isPasswordVisible: false,
      isFocused: false,
    };
  },

  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.isPasswordVisible ? 'text' : 'password';
      }
      return this.type;
    },
    textOfSwitch() {
      return this.isPasswordVisible ? 'Скрыть' : 'Показать';
    },
    classes() {
      return [
        {
          'v-input--error': this.isError,
          'v-input--filled': this.filled,
          'v-input--disabled': this.disabled,
          'v-input--focused': this.isFocused,
        },
        this.size && `v-input--size--${this.size}`,
      ];
    },
    isValidTypeForMask() {
      // see more: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#known-issues
      return TEXT_MASK_AVAILABLE_INPUT_TYPES.includes(this.type);
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (this.textMask) {
          this.updateTextMaskValue(newValue);
        } else {
          this.localValue = newValue;
        }
      },
    },
    mask() {
      this.bindTextMask();
    },
    type() {
      this.bindTextMask();
    },
  },

  // beforeMounted() {
  mounted() {
    const { value } = useField(this.name, this.rules, {
      initialValue: this.modelValue,
    });

    this.localValue = value;
    this.bindTextMask();
  },

  methods: {
    bindTextMask() {
      if (this.mask && this.isValidTypeForMask) {
        this.textMask = Object.freeze(
          createTextMaskInputElement({
            inputElement: this.$refs.input,
            // more options: https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
            mask: this.mask,
            ...TEXT_MASK_CONFIG_DEFAULT,
            ...this.textMaskConfig,
          }),
        );

        // format value by mask and emit 'input' event
        this.updateTextMaskValue(this.localValue);
      } else {
        this.textMask = null;
      }
    },
    updateTextMaskValue(value) {
      this.textMask.update(value);
      this.localValue = this.$refs.input.value;

      // not fire 'input' event, if value is not changed
      if (this.localValue !== this.modelValue) {
        this.$emit('update:modelValue', this.localValue);
      }
    },
    handleInput(value) {
      if (this.textMask) {
        this.updateTextMaskValue(value);
      } else {
        this.localValue = value;
        this.$emit('update:modelValue', this.localValue);
      }
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
.v-input {
  $this: &;

  display: flex;
  align-items: center;
  position: relative;
  background-color: get-theme-for($text-field, 'background', 'enabled');
  border: 1px solid get-theme-for($text-field, 'border-color', 'enabled');
  border-radius: $--main-border-radius;
  padding: 4px 16px;
  transition: all $--theme-transition;

  &:hover {
    border-color: get-theme-for($text-field, 'border-color', 'enabled');
  }

  &--error {
    border: 1px solid get-theme-for($text-field, 'border-color', 'error');
    transition: all $--theme-transition;
  }

  &--disabled {
    &:hover {
      cursor: not-allowed;
    }

    #{$this}__element {
      color: get-theme-for($text, 'disabled');
      -webkit-text-fill-color: get-theme-for($text, 'disabled');

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &--focused {
    background-color: get-theme-for($text-field, 'background', 'pressed');
    border-color: get-theme-for(get-theme-for($text-field, 'border-color', 'pressed'));
  }

  .v-input__element {
    @include transparent-autofill;

    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    color: get-theme-for($text, 'primary');
    font-size: 18px;
    line-height: 32px;

    &::placeholder {
      color: get-theme-for($text, 'disabled');
    }
  }

  .v-input__button-switch {
    color: get-theme-for($text, 'disabled');
    flex: 0 0 62px;
    line-height: initial;

    &:hover{
      color: get-theme-for($text, 'secondary');
    }
  }

  .v-input__prepend,
  .v-input__append {
    display: flex;
    align-items: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
