<template>
  <div
    v-if="visible"
    class="v-alert"
  >
    <v-icon
      v-if="isIconCross"
      name="cross"
      size="14"
      class="v-alert__icon-cross"
      @click="close"
    />
    <div
      class="v-alert__content"
      :class="classes"
    >
      <v-icon
        :name="iconName"
        class="v-alert__icon-content"
      />
      <div class="v-alert__text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '@/components/common/VIcon.vue';

const ICON_NAME_MAP = {
  error: 'infoCircle',
  success: 'check',
};

export default {
  name: 'VAlert',
  components: {
    VIcon,
  },
  props: {
    type: { type: String, required: true },
    text: { type: String, required: true },
    id: { type: Number, required: true },
    timeout: { type: Number, default: 5000 },
    isIconCross: { type: Boolean, default: false },
  },
  emits: ['close'],
  data() {
    return {
      visible: true,
      closeTimer: null,
    };
  },
  computed: {
    classes() {
      return {
        'v-alert__content--error': this.type === 'error',
        'v-alert__content--success': this.type === 'success',
      };
    },
    iconName() {
      return ICON_NAME_MAP[this.type];
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val) this.visible = true;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.closeTimer = setTimeout(this.close, this.timeout);
      },
    },
  },
  beforeUnmount() {
    clearTimeout(this.closeTimer);
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close', this.id);
    },
  },
};
</script>

<style lang="scss">
.v-alert {
  display: flex;
  // white-space: nowrap;
  justify-content: center;
  position: relative;
  transition: all 0.3s;

  &__icon {
    &-cross {
      position: absolute;
      top: 13px;
      right: 2px;
      stroke: $--white;
      cursor: pointer;
    }
  }

  &__icon-content {
    //margin-right: 5px;
  }

  &__text { margin-left: 5px; }

  &__content {
    &--error {
      background-color: #f45e49;
      min-width: 275px;

      .v-alert__icon-content {
        font-size: 24px;
      }
    }

    &--success {
      background-color: $--green;
      min-width: 270px;
    }

    margin: 10px 0 15px;
    padding: 15px;
    display: flex;
    // align-items: center;
    border-radius: 14px 0 14px 14px;
  }
}
</style>

<style lang="scss">
.notification {
  word-break: break-word;
  overflow-wrap: anywhere;
}
</style>
