import { defaultApiQuery, updateApiQuery } from '@/composables/useApi';
import generateUUID from '@/utils/uuid';
import CryptoJS from 'crypto-js';

/**
 * Возвращает дату, которая наступит через days дней 
 * @param {Number} days 
 * @returns Date 
 */
function getExpireDate(days) {
    const date = new Date();

    date.setUTCDate(date.getUTCDate() + days);

    return date;
}

/**
 * Парсит строку юзер-агента и возвращает объект с названием браузера и его версией 
 * @param {String} userAgentString 
 * @returns Object with browser and version
 */
function parseUserAgent(userAgentString) {
    // Список браузеров в порядке от менее популярного к более популярному
    const browsers = [
        'SamsungBrowser', 'UCBrowser', 'Opera Mini', 'Opera Mobi',
        'Silk', 'QQBrowser', 'Baidu', 'SeaMonkey', 'Maxthon',
        'Puffin', 'iCab', 'Avant Browser', 'AOL Shield', 'Blisk',
        'Brave', 'Camino', 'Comodo Dragon', 'Conkeror', 'CoolNovo',
        'Deepnet Explorer', 'Dillo', 'Dooble', 'Epic', 'Flock',
        'Galeon', 'GreenBrowser', 'HotJava', 'IBM WebExplorer', 'IBrowse',
        'iCab', 'Iceape', 'IceCat', 'IceDragon', 'Iridium',
        'Iron', 'K-Meleon', 'Konqueror', 'Links', 'Lunascape',
        'Lynx', 'Maxthon', 'Midori', 'Minimo', 'Mosaic',
        'NetPositive', 'Netscape', 'NetSurf', 'OmniWeb',
        'Otter Browser', 'Pale Moon', 'Phoenix', 'Polaris',
        'QtWeb Internet Browser', 'Roccat', 'SEMC-Browser', 'Skyfire',
        'SlimBrowser', 'Swiftfox', 'Tizen', 'UCBrowser', 'Vivaldi',
        'Waterfox', 'wOSBrowser', 'YaBrowser', 'Firefox',
        'Opera', 'Edg', 'Chrome', 'Safari', 'Mozilla',
    ];


    for (let browser of browsers) {
        let browserVersion = userAgentString.split(browser + '/')[1];
        if (browserVersion) {
            browserVersion = browserVersion.split(' ')[0];
            return {
                browser: browser,
                version: browserVersion,
            };
        }
    }

    return {
        browser: 'Unknown',
        version: 'Unknown',
    };
}

/**
 * Шифрует текст с помощью SKEY
 * @param {String} text 
 * @param {String} SKEY 
 * @returns String
 */
const encrypt = (text, SKEY) => {
    if (!text) return text;
    // const { vueApp: { CryptoJS } } = useNuxtApp();
    return CryptoJS.AES.encrypt(text, SKEY).toString();
};

/**
 * Расшифровывает текст с помощью SKEY
 * @param {String} encrypted 
 * @param {String} SKEY 
 * @returns String
 */
const decrypt = (encrypted, SKEY) => {
    if (!encrypted) return encrypted;
    // const { vueApp: { CryptoJS } } = useNuxtApp();
    return CryptoJS.AES.decrypt(encrypted, SKEY).toString(CryptoJS.enc.Utf8);
};

function updateMacToUuid() {
    if (!defaultApiQuery.Mac || defaultApiQuery.Mac === '123') {
        let uuid = useCookie('uuid').value;

        if (!uuid) {
            uuid = generateUUID();

            let expiryDate = getExpireDate(365);

            useCookie('uuid', { expires: expiryDate }).value = uuid;
        }

        updateApiQuery({ Mac: uuid });
    }
}

export { getExpireDate, parseUserAgent, encrypt, decrypt, updateMacToUuid };