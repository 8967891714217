import { shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { toBoolean, toNumber } from '@/utils/typehint';

export const useChannelsListStore = defineStore('channelsList', () => {
  const { fetch: fetchChanges } = useApiData('channel/changes');

  const { data, fetch: fetchChannels } = useApiData('channel/list');
  const { isAuthenticated, user } = storeToRefs(useUserStore());
  const favoritesStore = useFavoritesListStore();
  const { favoritesList } = storeToRefs(favoritesStore);
  const categories = computed(() => data.value?.categories || []);
  const channelsList = computed(() => {
    return (data.value?.channels || [])
      .map(c => {
        c.id = toNumber(c.id);
        c.has_record = toBoolean(c.has_record);
        c.icon = replaceChannelIcon(c);
        if (!isAuthenticated.value) {
          c.locked = true;
        }
        return c;
      })
      .sort((a, b) => (a.locked && !b.locked ? 1 : -1));
  });

  const channelsFav = computed(() => {
    const chans = channelsList.value.map(channel => ({
      ...channel,
      // popularity_lock_rating: (channel.locked ? 1000000 : 0) + (parseInt(channel.popularity_rating) === 0 ? 999999 : parseInt(channel.popularity_rating)),
    }));

    // merge channels/favorites
    if (isAuthenticated.value) {
      const favs = favoritesList.value
        .filter(fav => fav.fav_type === 'channel')
        .reduce((memo, fav) => {
          const ci = chans.findIndex(ch => fav.channel_id == ch.id);
          if (ci !== -1) {
            chans[ci].fav = fav;
            memo.push({ ...chans[ci], ...fav });
          }
          return memo;
        }, []);

      // проверка каналов на доступность:
      const show_unsubscribed = shallowRef(Number(user.value?.current_profile?.show_unsubscribed) === 1 || null);
      return favs.filter(ch => {
        const channel = currentChannel(ch.id);
        return !(!channel.id || (!show_unsubscribed.value && channel.locked));
      });
    }
    return chans;
  });

  function currentChannel(id) {
    // серверы отдают разные типы переменных, потому ==
    return channelsList.value.find(c => c.id == id) || {};
  }

  function replaceChannelIcon(channel, size = 130) {
    return channel.icon?.replace('/icons/', '/icons' + size + 'png/').replace('.gif', '.png');
  }

  const categorySelected = shallowRef('1');
  const setCategorySelected = (id) => categorySelected.value = id;

  return {
    channelsList,
    categories,
    channelsFav,
    categorySelected,
    //
    fetchChannels,
    fetchChanges,
    currentChannel,
    replaceChannelIcon,
    setCategorySelected,
  };
});
