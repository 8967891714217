<template>
  <div
    class="v-card"
    :style="{ 'max-width': maxWidth }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VCard',
  props: {
    maxWidth: {
      type: String,
      default: '560px',
    },
  },
};
</script>

<style lang="scss">
.v-card {
  padding: 56px 48px 80px;
  background: $--black-200;
  border-radius: $--border-radius-large;

  @include phone {
    padding: 44px 16px 32px;
  }
}
</style>
