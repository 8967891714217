import useApi from '@/composables/useApi';
import { useUserStore } from '@/store/user';

export function sendMobileRegister(url) {
  const userStore = useUserStore();

  return useApi(url, {
    params: { ...userStore.requiredParams },
    method: 'GET',
    // body: JSON.stringify(credentials)
  });
}
export function sendMobileRegisterSMS(params) {
  const userStore = useUserStore();

  return new Promise((resolve, reject) => {
    return useApi('/mobile/register', {
      params: { ...params, ...userStore.requiredParams },
      method: 'GET',
      // body: JSON.stringify(credentials)
    }).then(res => {
      return resolve(res.data);
      // return res
    });
  });
}

export function logoutApi() {
  return useApi('/logout');
}
