<template>
  <v-dialog
    v-model:visible="localVisible"
    size="large"
  >
    <template #content>
      <div class="profile-pin-code-changes">
        <header class="profile-pin-code-changes__header">
          <h1 class="profile-pin-code-changes__title">
            Изменить пинкод для профиля "{{ profileName }}"
          </h1>
        </header>
        <validation-observer v-slot="{ errors, handleSubmit }">
          <v-form
            class="profile-pin-code-changes__form"
            @submit.prevent="handleSubmit(changeCode)"
          >
            <template #default>
              <div class="profile-pin-code-changes__form-main">
                <v-form-row class="profile-pin-code-changes__form-row">
                  <v-form-field>
                    <h4 class="profile-pin-code-changes__form-field-label">
                      Введите старый пинкод
                    </h4>
                    <v-otp-input
                      v-model="oldCode"
                      :length="4"
                      :error-message="errors.oldCode"
                      name="oldCode"
                      :is-error="isError || errors.oldCode"
                      rules="required"
                    />
                  </v-form-field>
                </v-form-row>
                <v-form-row class="profile-pin-code-changes__form-row">
                  <v-form-field>
                    <h4 class="profile-pin-code-changes__form-field-label">
                      Введите новый пинкод
                    </h4>
                    <v-otp-input
                      v-model="code"
                      :length="4"
                      :error-message="errors.code"
                      name="code"
                      :is-error="isError || errors.code"
                      rules="required"
                    />
                  </v-form-field>
                </v-form-row>
              </div>
            </template>
            <template #footer>
              <footer class="profile-pin-code-changes__form-footer">
                <v-button
                  class="profile-pin-code-changes__form-button"
                  primary
                  @click="back"
                >
                  Назад
                </v-button>
                <v-button
                  type="submit"
                  class="profile-pin-code-changes__form-button"
                  success-text
                  primary
                >
                  Сохранить
                </v-button>
              </footer>
            </template>
          </v-form>
        </validation-observer>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VOtpInput from '@/components/common/VOtpInput.vue';
import VButton from '@/components/common/VButton.vue';
import { Form as ValidationObserver } from 'vee-validate';
import VDialog from '@/components/common/VDialog.vue';

export default {
  name: 'PinCodeChangesDialog',
  components: {
    VForm,
    VFormRow,
    VFormField,
    VOtpInput,
    VButton,
    VDialog,
    ValidationObserver,
  },
  props: {
    phone: {
      type: String,
      default: '',
    },
    profileName: { type: String, default: '' },
    visible: { type: Boolean, default: false },
  },
  emits: ['submitted', 'back', 'update:visible'],
  data() {
    return {
      oldCode: '',
      newCode: '',
    };
  },
  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
  methods: {
    back() {
      this.resetModel();
      this.localVisible = false;
    },
    changeCode() {
      this.resetModel();
      this.localVisible = false;
    },
    resetModel() {
      this.oldCode = '';
      this.newCode = '';
    },
  },
};
</script>

<style lang="scss">
.profile-pin-code-changes {
  max-width: 560px;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding: 73px 48px 80px;

  @include phone {
    padding: 44px 16px 42px;
  }

  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;
    color: $--white;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      font-weight: $--font-weight-medium;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--white-05percent;
    font-size: $--font-size-subtitle-phone;
    line-height: 21px;

    span {
      color: $--white;
      font-weight: $--font-weight-medium;
    }

    @include phone {
      font-size: $--font-size-small;
      line-height: 16px;
    }
  }

  &__form {
    &-main {
      margin-bottom: 64px;
      padding: 0 48px 0;

      @include phone {
        margin-bottom: 22px;
      }
    }

    &-row {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      @include phone {
        margin-bottom: 8px;
      }
    }

    &-field-label {
      color: $--white;
      font-weight: $--font-weight-regular;
      font-size: $--font-size-subtitle-phone;
      text-align: center;
      margin-bottom: 8px;

      @include phone {
        font-size: $--font-size-small;
      }
    }

    &-input {
      &::placeholder {
        font-weight: $--font-weight-medium;
        font-size: 24px;
        letter-spacing: normal;
      }
    }

    &-help {
      text-align: center;
    }

    &-footer {
      display: flex;
      column-gap: 16px;

      @include phone {
        padding: 0;
        row-gap: 8px;
        flex-direction: column-reverse;
      }
    }

    &-button {
      width: 100%;

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
      }
    }
  }
}
</style>
