<template>
  <label class="v-checkbox">
    <input
      v-model="localChecked"
      type="checkbox"
      class="v-checkbox__input"
      :disabled="disabled"
      v-bind="{ ...$attrs, ...binder }"
    >
    <span
      class="v-checkbox__mark"
      :class="classes"
    >
      <slot name="mark" />
    </span>
    <span class="v-checkbox__label">
      <slot name="label">{{ label }}</slot>
    </span>
  </label>
</template>

<script>
import { useField } from 'vee-validate';

export default {
  name: 'VCheckbox',
  props: {
    checked: {
      type: [Boolean, String, Object, Number, Array],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, Number, String, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: { type: String, required: true },
    rules: { type: [Function, String, Object, Array], default: '' },
    trueValue: { type: [String, Boolean, Object, Array, Number], default: null },
    falseValue: { type: [String, Boolean, Object, Array, Number], default: null },
  },
  emits: ['update:checked'],
  data() {
    return {
      handleChange: null,
    };
  },
  computed: {
    classes() {
      return [
        {
          'v-checkbox__mark--error': this.isError,
        },
      ];
    },
    binder() {
      const boundValues = {};
      if (this.value) boundValues.value = this.value;
      if (this.trueValue || this.falseValue) {
        boundValues['true-value'] = this.trueValue;
        boundValues['false-value'] = this.falseValue;
      }
      return boundValues;
    },
    localChecked: {
      get() {
        return this.checked;
      },
      set(value) {
        this.handleChange(value);
        this.$emit('update:checked', value);
      },
    },
  },
  mounted() {
    const { handleChange } = useField(this.name, this.rules, {
      type: 'checkbox',
      checkedValue: this.checked,
    });

    this.handleChange = handleChange;
  },
};
</script>

<style lang="scss">
@mixin visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.v-checkbox {
  $block: &;
  $mark-size: 1em;

  cursor: pointer;

  &__input {
    @include visually-hidden;

    &:checked ~ #{$block}__mark {
      background-color: $--green;
      border-color: transparent;

      &::after {
        transform: rotate(0deg) scaleY(1);
      }
    }
    &:focus-visible ~ #{$block}__mark {
      outline-color: #334756;
    }

    &:disabled {
      ~ #{$block}__mark {
        background: rgba(51, 71, 86, 0.25);
        border-color: transparent;
        cursor: not-allowed;

        &:hover {
          border-color: transparent;
        }
      }
      ~ #{$block}__label {
        color: gray;
      }
      &:checked ~ #{$block}__mark {
        background-color: $--green;

        &::after {
          transform: rotate(0deg) scaleY(1);
        }
      }
    }
  }

  &__mark {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    background-color: $--white;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 1px solid #334756;
    outline: 2px solid transparent;
    border-radius: 6px;
    vertical-align: middle;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
      background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), outline 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);

    &:hover {
      border-color: $--green;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &::after {
      content: '';
      box-sizing: content-box;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-image: url(/assets/icons/checkWhite.svg);
      transform: rotate(45deg) scaleY(0);
      transition: transform 0.15s ease-in 0.05s;
      transform-origin: center;
      margin-left: -1px;
    }

    &--error {
      border-color: get-theme-for($text-field, 'border-color', 'error');
    }
  }
}
</style>
