const bannerCardBreakPoints = {
  600: { spaceBetween: 6 },
  720: { spaceBetween: 9 },
  1024: { spaceBetween: 12 },
  1280: { spaceBetween: 16 },
  1440: { spaceBetween: 16 },
  1920: { spaceBetween: 26 },
  2560: { spaceBetween: 26 },
};

// for: program, channel, poster
const commonBreakPoints = {
  600: { spaceBetween: 10 },
  720: { spaceBetween: 13 },
  1024: { spaceBetween: 16 },
  1280: { spaceBetween: 20 },
  1440: { spaceBetween: 20 },
  1920: { spaceBetween: 30 },
  2560: { spaceBetween: 30 },
};

const epgBreakPoints = {
  200: { slidesPerView: 3 },
  300: { slidesPerView: 4 },
  400: { slidesPerView: 5 },
  500: { slidesPerView: 6 },
  600: { slidesPerView: 7 },
  720: { slidesPerView: 7 },
  960: { slidesPerView: 4 }, // MacBook
  1024: { slidesPerView: 4 },
  1280: { slidesPerView: 4 },
  1440: { slidesPerView: 5 },
  1680: { slidesPerView: 5 }, // MacBook
  1920: { slidesPerView: 5 },
  2560: { slidesPerView: 6 },
};

export {
  bannerCardBreakPoints,
  commonBreakPoints,
  epgBreakPoints,
};
