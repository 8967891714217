<template>
  <div class="footer">
    <div class="footer__container container">
      <div class="footer__logo">
        <v-logo size="big" />
      </div>
      <div class="footer__row">
        <div class="footer__row-wrapper">
          <div class="footer__row-wrapper-label">
            Приложения Moovi всегда под рукой
          </div>
          <div class="footer__row-content">
            <v-button
              v-for="{ iconName, subtitle, mainText, href } in APPLICATION_STORE"
              :key="iconName"
              compressed
              primary
              :href="href"
              class="footer__button"
            >
              <div class="footer__button-content">
                <v-icon
                  :name="iconName"
                  class="footer__button-icon"
                  :size="iconsSize"
                />
                <div class="footer__button-text">
                  <span class="footer__button-subtitle">{{ subtitle }}</span>
                  <span class="footer__button-main-text">{{ mainText }}</span>
                </div>
              </div>
            </v-button>
          </div>
        </div>
        <div
          v-if="!mediaQueries.tablet && !mediaQueries.phone"
          class="footer__row-wrapper"
        >
          <div class="footer__row-wrapper-label footer__row-wrapper-label--end-align">
            Нужна помощь?
          </div>
          <v-button
            primary
            compressed
            :to="{ name: 'questions' }"
            class="footer__button footer__button--faq"
          >
            <div class="footer__text-faq">
              Ищите ответы на популярные вопросы
            </div>
          </v-button>
        </div>
      </div>
      <div class="footer__row footer__row--wrap">
        <div class="footer__row-wrapper footer__row-wrapper--margin">
          <div class="footer__row-wrapper-label">
            Смотрите Moovi на своём телевизоре
          </div>
          <div class="footer__row-content">
            <v-button
              v-for="{ iconName, subtitle, mainText, href } in VIEW_TV"
              :key="iconName"
              compressed
              primary
              :href="href"
              class="footer__button"
            >
              <div class="footer__button-content">
                <v-icon
                  :name="iconName"
                  class="footer__button-icon"
                  :size="iconsSize"
                />
                <div class="footer__button-text">
                  <span class="footer__button-subtitle">{{ subtitle }}</span>
                  <span class="footer__button-main-text">{{ mainText }}</span>
                </div>
              </div>
            </v-button>
          </div>
        </div>
        <div
          v-if="!mediaQueries.desktop"
          class="footer__row-content"
        >
          <div class="footer__row-content-item">
            <div class="footer__row-wrapper-label">
              Нужна помощь?
            </div>
            <v-button
              primary
              compressed
              to="/questions"
              class="footer__button footer__button--faq"
            >
              <div class="footer__text-faq">
                Ищите ответы на популярные вопросы
              </div>
            </v-button>
          </div>
        </div>
        <div
          v-if="!mediaQueries.phone"
          class="footer__row-content footer__row-content--social"
        >
          <div class="footer__row-content-item">
            <div class="footer__row-wrapper-label">
              Мы в социальных сетях
            </div>
            <div class="footer__row-content footer__row-content--social">
              <v-button
                v-for="{ name, link } in SOCIAL_NETWORKS"
                :key="name"
                :icon="name"
                :href="link"
              />
            </div>
          </div>
          <div class="footer__row-content-item">
            <div class="footer__row-wrapper-label footer__row-wrapper-label--end-align">
              Мы в мессенджерах
            </div>
            <div class="footer__row-content footer__row-content--social">
              <v-button
                v-for="{ name, link } in MESSENGERS"
                :key="name"
                :icon="name"
                :href="link"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="footer__row-content footer__row-content--social"
        >
          <div class="footer__row-content-item">
            <div class="footer__row-wrapper-label">
              Cвяжитесь с нами
            </div>
            <div class="footer__row-content footer__row-content--social">
              <v-button
                v-for="{ name, link } in [...MESSENGERS, ...SOCIAL_NETWORKS]"
                :key="name"
                :icon="name"
                :href="link"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer__row">
        <div class="footer__row-content-link">
          <a
            class="footer__link"
            href=""
          > Пользовательское соглашение </a>
          <a
            class="footer__link"
            href=""
          > Политика конфеденциальности </a>
        </div>
        <div class="footer__row-content-link footer__row-content-link--end-align">
          <div class="footer__link-text">
            Вы можете написать в службу поддержки
          </div>
          <a
            class="footer__link"
            href=""
          >Support@moovi-tv.ru</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import VButton from '@/components/common/VButton.vue';
import VLogo from '@/components/common/VLogo.vue';
import VIcon from '@/components/common/VIcon.vue';
import { useMediaQueries } from '@/composables/useMediaQueries';

const mediaQueries = useMediaQueries();
const SOCIAL_NETWORKS = [
  {
    name: 'vk',
    link: 'https://vk.com/',
  },
  {
    name: 'ok',
    link: 'https://ok.com/',
  },
];

const MESSENGERS = [
  {
    name: 'telegram',
    link: 'https://vk.com/',
  },
  {
    name: 'whatsapp',
    link: 'https://ok.com/',
  },
  {
    name: 'viber',
    link: 'https://ok.com/',
  },
];

const APPLICATION_STORE = [
  {
    iconName: 'appleLogo',
    href: 'https://www.apple.com/ru/app-store/',
    subtitle: 'Загрузить в',
    mainText: 'App Store',
  },
  {
    iconName: 'googlePlay',
    href: 'https://play.google.com',
    subtitle: 'Доступно в',
    mainText: 'Google Play',
  },
  {
    iconName: 'appGallery',
    href: 'https://consumer.huawei.com/ru/mobileservices/appgallery/',
    subtitle: 'Доступно в',
    mainText: 'AppGallery',
  },
];

const VIEW_TV = [
  {
    iconName: 'smartTv',
    href: '',
    subtitle: 'Включить на',
    mainText: 'Smart TV',
  },
  {
    iconName: 'allDevice',
    href: '',
    subtitle: 'Смотреть на всех',
    mainText: 'Устройствах',
  },
];

const iconsSize = computed(() => {
  return mediaQueries.value.phone ? '28' : '40';
});
</script>

<script>
export default {
  name: 'TheFooter',
};
</script>

<style lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: get-theme-for($background, 'footer');
  width: 100%;
  align-items: center;

  &__logo {
    margin-top: 64px;
  }

  &__button {
    width: 208px;
    padding: 17px 32px;
    white-space: nowrap;

    @include tablet {
      width: 190px;
    }

    @include phone {
      width: 146px;
      height: 56px;
      padding: 12px 16px;
    }

    &--faq {
      width: 220px;
      // TODO: Придумать как решить иначе (поменять порядок стилей)
      padding: 10px 23px !important;
      white-space: normal;

      @include phone {
        width: 100%;
      }
    }

    &-text {
      text-align: start;
      margin-left: 8px;
    }

    &-content {
      display: flex;
      font-weight: $--font-weight-regular;
      font-size: $--font-size-larger;
    }

    &-subtitle {
      font-style: normal;
      font-weight: $--font-weight-regular;
      font-size: $--font-size-small;
      line-height: 16px;
      opacity: 0.5;
      display: block;

      @include phone {
        font-size: $--font-size-extra-small;
        line-height: 12px;
      }
    }

    &-main-text {
      margin: 0;
      font-style: normal;
      font-weight: $--font-weight-medium;
      font-size: 20px;
      line-height: 150%;

      @include phone {
        font-size: $--font-size-base;
        line-height: 120%;
      }
    }
  }

  &__button-icon {
    //margin-right: 8px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 62px;

    &:last-of-type {
      margin: 104px 0 84px 0;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    @include tablet {
      margin-top: 80px;
      row-gap: 80px;

      &:first-of-type {
        margin-top: 56px;
      }

      &:last-of-type {
        margin: 88px 0 120px 0;
      }
    }

    @include phone {
      margin-top: 48px;

      &:last-of-type {
        flex-direction: column;
        margin: 64px 0 80px 0;
      }
    }
  }

  &__text-faq {
    width: 175px;
    font-weight: $--font-weight-medium;
    font-size: $--border-radius-small;
    line-height: 24px;
    text-align: right;

    @include tablet {
      width: auto;
      text-align: inherit;
      font-size: $--font-size-base;
      line-height: 130%;
    }
  }

  &__row-wrapper {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__row-wrapper-label {
    font-weight: $--font-weight-regular;
    font-size: $--font-size-larger;
    line-height: 20px;
    margin-bottom: 20px;
    color: get-theme-for($text, 'disabled');

    &--end-align {
      @include tablet-and-higher {
        text-align: end;
      }
    }
  }

  &__row-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;

    @include tablet {
      row-gap: 12px;
    }

    @include phone {
      column-gap: 8px;
      row-gap: 8px;
    }

    &--end-align {
      @include tablet {
        justify-content: flex-end;
      }
    }

    &--nowrap {
      flex-wrap: nowrap;
    }

    &--social {
      column-gap: 20px;

      @include only-tablet {
        flex-direction: row-reverse;
      }

      @include phone {
        column-gap: 15px;
      }
    }
  }

  &__row-content-item {
    &:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: self-end;
      margin-right: 22px;

      @include tablet {
        align-items: flex-start;
        margin-right: 0;
      }
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__row-content-link {
    display: flex;
    column-gap: 24px;

    @include tablet {
      row-gap: 16px;
      flex-wrap: wrap;
      width: 340px;

      &--end-align {
        @include only-tablet {
          justify-content: flex-end;
        }
      }
    }

    @include phone {
      width: auto;

      &:nth-child(2) {
        order: -10;
        margin-bottom: 40px;
      }
    }
  }

  &__link-text {
    color: get-theme-for($text, 'disabled');
    @include only-tablet {
      text-align: right;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: get-theme-for($text, 'disabled');
  }
}
</style>
