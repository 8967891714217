<template>
  <div
    :style="styles"
    :class="className"
  >
    <img
      v-if="isImageUrl"
      :src="name"
      alt="Image"
    >
    <component
      :is="icon"
      v-else
      v-bind="$attrs"
      :style="styles"
    >
      <title v-if="title">
        {{ title }}
      </title>
    </component>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: { type: [String, Number], default: '1em' },
  title: { type: String, default: '' },
});

const icon = computed(() =>
  defineAsyncComponent(() => import(`../../assets/icons/${props.name}.svg`)),
);

const isImageUrl = computed(() =>
  props.name.startsWith('http://') || props.name.startsWith('https://'),
);

const className = computed(() => {
  return `v-icon v-icon--${props.name}`;
});

const styles = computed(() => {
  const size = Number(props.size);

  return {
    width: Number.isNaN(size) ? props.size : `${size}px`,
    height: Number.isNaN(size) ? props.size : `${size}px`,
  };
});
</script>

<style lang="scss">
.v-icon {
  flex-shrink: 0;
  fill: currentColor;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}
</style>
