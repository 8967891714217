<template>
  <v-card class="registration-send-code">
    <v-card-header class="registration-send-code__header">
      <template #title>
        Введите код
      </template>
      <template #subtitle>
        Для {{ isRegistration ? 'регистрации' : 'входа' }} на ваш номер телефона отправлен код подтверждения
      </template>
    </v-card-header>
    <validation-observer v-slot="{ errors, handleSubmit }">
      <v-form
        class="registration-send-code__form"
        @submit.prevent="handleSubmit(sendCode)"
      >
        <template #default>
          <div class="registration-send-code__form-main">
            <v-form-row class="registration-send-code__form-row">
              <v-form-field
                class="registration-send-code__form-field"
                :error-message="errors.code"
              >
                <!-- name="code" -->
                <v-otp-input
                  v-model="code"
                  :length="4"
                  :error-message="errors.code"
                  :is-error="isError || errors.code"
                  rules="required"
                  @submit="handleSubmit(sendCode)"
                />
              </v-form-field>
            </v-form-row>

            <v-timer
              :time="5"
              class="registration-send-code__timer"
            >
              <template #timer="{ resetTimer, currentTime }">
                <v-button
                  class="registration-send-code__resend-code-button"
                  type="button"
                  is-text
                  :disabled="!!currentTime"
                  @click="sendCodeToAgain(resetTimer)"
                >
                  Отправить повторно {{ currentTime ? ` через ${currentTime} сек.` : '' }}
                </v-button>
              </template>
            </v-timer>
          </div>
        </template>
        <template #footer>
          <slot
            name="actions"
            v-bind="{ sendCode }"
          />
        </template>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { ref } from 'vue';
import { sendMobileRegisterSMS, sendMobileRegister } from '@/api/sendMobileRegister';
import VCard from '@/components/common/VCard.vue';
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VOtpInput from '@/components/common/VOtpInput.vue';
import VCardHeader from '@/components/common/VCardHeader.vue';
import VTimer from '@/components/common/VTimer.vue';
import VButton from '@/components/common/VButton.vue';
import { Form as ValidationObserver } from 'vee-validate';
import { alertService } from '@/services/alert';
// import authService from '@/services/auth'
import { useUserStore } from '@/store/user';

export default {
  name: 'RegistrationSendCode',
  components: {
    VCard,
    VForm,
    VFormRow,
    VFormField,
    VButton,
    VOtpInput,
    VTimer,
    ValidationObserver,
    VCardHeader,
  },
  props: {
    phone: {
      type: String,
      default: '',
    },
    isRegistration: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['registration-success'],
  setup(props, { emit }) {
    const userStore = useUserStore();
    const { signIn } = useAuth();
    const code = ref('');
    const isError = ref(false);

    const formattedPhone = phone => {
      return phone.replaceAll(/[^0-9]/g, '').slice(1);
    };

    const sendCode = () => {
      if (code.value.length === 4) {
        sendMobileRegisterSMS({
          confirmPin: code.value,
          confirm: 1,
          phoneNumber: formattedPhone(props.phone),
        }).then(({ clientId, password, error, errorDescription }) => {
          if (error === 0) {
            signIn('credentials', {
              redirect: false,
              email: clientId,
              password: password,
              requiredParams: userStore.requiredParamsToString,
            }).then(status => {
              if (status.error) {
                // Do your custom error handling here
                alertService.error({
                  text: errorDescription || 'Пользователь не найден',
                  isIconCross: true,
                  timeout: 4500,
                });
              } else {
                // No error, continue with the sign in, e.g., by following the returned redirect:
                // return navigateTo(url, { external: true })
                emit('registration-success');
                alertService.success({ text: 'Авторизация прошла успешно', isIconCross: true, timeout: 4500 });
              }
            });
          } else {
            alertService.error({
              text: errorDescription || 'Неверный код, попробуйте ещё раз',
              isIconCross: true,
              timeout: 4500,
            });
            code.value = '';
            isError.value = true;
          }
        });
      } else {
        alertService.error({ text: 'Введите корректный пин-код', isIconCross: true, timeout: 4500 });
      }
    };

    const sendCodeToAgain = resetTimer => {
      resetTimer();
      code.value = '';
      sendMobileRegister(`/mobile/register?askconfirm=1&phone_number=${this.phone.replaceAll(/[^0-9]/g, '').slice(1)}`);
    };

    return {
      sendCode,
      code,
      sendCodeToAgain,
      isError,
    };
  },
};
</script>

<style lang="scss">
.registration-send-code {
  max-width: 560px;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding-top: 138px;

  @include phone {
    padding-top: 78px;
  }

  &__header {
    margin-bottom: 32px;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      font-weight: $--font-weight-medium;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--light-gray-300;
    font-size: $--font-size-subtitle-phone;
    line-height: 32px;

    @include phone {
      font-size: $--font-size-small;
      line-height: 16px;
    }
  }

  &__form {
    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;

      @include phone {
        margin-bottom: 63px;
      }
    }

    &-row {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      @include phone {
        margin-bottom: 8px;
      }
    }

    &-input {
      &::placeholder {
        color: get-theme-for($text, 'primary') !important;
      }
    }

    &-help {
      text-align: center;
    }

    &-buttons-wrapper {
      width: 100%;
      display: flex;
      column-gap: 16px;

      @include phone {
        flex-direction: column-reverse;
        padding: 0;
        row-gap: 8px;
      }
    }

    &-button {
      width: 50%;

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
        width: 100%;
      }
    }
  }

  &__timer {
    text-align: center;
  }

  &__resend-code-button {
    font-size: 14px;
    text-decoration: underline;

    @include phone {
      margin-bottom: 12px;
    }
  }
}
</style>
