<script setup>
import { computed } from 'vue';
import { useSettingsStore } from '@/store/settings';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  isLocal: {
    type: Boolean,
    default: true, // По умолчанию изображение берется с локальной папки
  },
});

const settingsStore = useSettingsStore();
const path = computed(() => {
  if (props.isLocal) return `/icons/${props.icon}.svg`;

  const iconPath = settingsStore.settings?.CLIENT_SETTINGS?.mainmenu_icons_path;
  if (!iconPath) return false;
  return `${iconPath}/web_client/${props.icon}.svg`;
});
</script>

<template>
  <img
    v-if="path"
    :src="path"
  >
</template>
