<template>
  <div class="v-stepper">
    <template v-for="step in stepsCount">
      <slot
        v-if="step === activeStep"
        :name="step"
        :change-active-step="changeActiveStep"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'VStepper',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      activeStep: 1,
    };
  },
  computed: {
    stepsCount() {
      return Object.keys(this.$slots)?.length;
    },
  },

  watch: {
    currentStep: {
      immediate: true,
      handler(step) {
        this.changeActiveStep(step);
      },
    },
  },
  methods: {
    changeActiveStep(step) {
      this.activeStep = step;
    },
  },
};
</script>
