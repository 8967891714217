<template>
  <span class="form-field__error">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'VFromError',
};
</script>

<style lang="scss">
.form-field__error {
  color: get-theme-for($text, 'error');
  font-size: $--font-size-small;
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
}
</style>
