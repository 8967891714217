<template>
  <swiper
    slides-per-view="auto"
    :navigation="true"
    :modules="[Navigation]"
    class="season_swiper poster_detail"
  >
    <swiper-slide
      v-for="season in content"
      :key="season.id"
      class="poster_detail__season"
    >
      {{ season.id }}
      {{ currentSeason }}
      <button
        class="poster_detail__season__btn"
        :class="{ selected: currentSeasonId === season.id }"
        @click="selectSeason(season)"
      >
        {{ seasonTitle(season.title) }}
      </button>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import {onMounted} from "vue";

const emit = defineEmits(['setSeason']);
const props = defineProps({
  content: {
    type: Array,
    default: () => [],
  },
  currentSeason: {
    type: Number,
    default: 0
  }
});

const route = useRoute();
const currentSeasonId = shallowRef(0)

function selectSeason(season) {
  currentSeasonId.value = season.id;
  emit('setSeason', season);
}

function seasonTitle(title) {
  // переносим цифру в конец
  title = /^\d/.test(title) ? title.split(' ').reverse().join(' ') : title;
  return title.charAt(0).toUpperCase() + title.slice(1);
}

watch(
  () => props.content,
  _content => {
    if (!_content.contentId) return;
    currentSeasonId.value = _content.seasons[0].id;
  },
  { immediate: true },
);
watch(
    () => props.currentSeason,
    currentSeason => {
      currentSeasonId.value = currentSeason;
    },
    { immediate: true },
);
onMounted(() => {
  currentSeasonId.value = props.currentSeason;
})
</script>
