<template>
  <div class="v-group-buttons">
    <button
      v-for="(option, index) in options || buttons"
      :key="index"
      type="button"
      :class="{ selected: activeButton === index }"
      class="channel_page__btn v-group-buttons__item"
      @click="selectButton(index)"
    >
      {{ option.title || option }}
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  buttons: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: undefined,
  },
  activeButtonIndex: {
    type: Number,
    default: -1,
  },
  activeValue: {
    type: Number,
    default: -1,
  },
});

const getActiveIndex = () => {
  if (props.activeButtonIndex > -1) {
    return props.activeButtonIndex;
  }
  if (props.options && props.activeValue > -1) {
    return props.options.findIndex(option => Number(option.value) === Number(props.activeValue));
  }
};

const activeButton = ref(getActiveIndex());
const activeValue = computed(() => (props.options || props.buttons)[activeButton.value]?.value);

const emit = defineEmits(['update:index', 'update:value']);

const selectButton = index => {
  activeButton.value = index;
  emit('update:index', index);
  emit('update:value', activeValue.value);
};
</script>

<style lang="scss">
.v-group-buttons {
  display: flex;
  justify-content: space-between;
  &__item {
    height: auto;
    font-size: $--font-size-larger;
    border-radius: $--border-radius-extra-small;
    text-align: center;
    width: auto;
    padding: 10px 20px;
    margin: 0;
  }
}
</style>
