<template>
  <div
    class="tv_player"
    v-click-outside="hideControls"
  >
    <VideoPlayer
      v-if="src != null && isAuthenticated && !errorMessage && plStream.status == 0"
      :src="src"
      :loop="false"
      :volume="0.5"
      :poster="poster"
      :autoplay="plAutoplay ? 'play' : false"
      :muted="playerState.muted"
      class="vjs-fill"
      @timeupdate="onTimeUpdate"
      @error="onError"
      @mounted="onPlMounted"
      @pointermove="onMouseover"
      @pointerleave="onMouseleave"
      @click="onClickVideo"
      @play="onPlay"
      @pause="onPause"
      @ended="onEnded"
      @seeked="onSeeked"
      @fullscreenchange="onFullScreenChange"
      @exitFullWindow="onExitFullWindow"
      @touchstart="onMouseover"
    >
      <template #default="{ state }">
        <Transition>
          <div
            v-show="showControls"
            class="tv_player__controls flex"
          >
            <Icon
              class="tv_player__big_play_button pointer"
              :icon="state.playing ? 'player/pause' : 'player/play'"
              @click.stop="onPlToggle"
            />
            <button
              v-if="isMiniPlayer"
              class="tv_player__cross"
              type="button"
              @click="closeMiniPlayer"
            >
              <Icon icon="pins/pin_close" />
            </button>
            <div class="tv_player__control_button flex">
              <!-- Left side buttons -->
              <div class="tv_player__left_buttons flex">
                <button
                  class="tv_player__left_buttons__mr30"
                  @click.stop="onPlToggle"
                >
                  <Icon :icon="state.playing ? 'player/pause' : 'player/play'" />
                </button>
                <button
                  class="tv_player__left_buttons__mr30"
                  @click.stop="onToggleMute"
                >
                  <Icon :icon="playerState.muted ? 'player/volume_off' : 'player/volume_on'" />
                </button>
                <div class="tv_player__current_time">
                  {{ displayPosition }}
                </div>
              </div>

              <!-- Right side buttons -->
              <div class="tv_player__right_buttons flex">
                <Icon
                  v-if="plMode === 'archive'"
                  class="pointer tv_player__right_buttons__ml60"
                  icon="epg/icon_live"
                  @click.stop="onGoLive"
                />
                <!--                <button-->
                <!--                  class="tv_player__right_buttons__ml60"-->
                <!--                  @click.stop="onOpenSettings"-->
                <!--                >-->
                <!--                  <Icon icon="player/settings" />-->
                <!--                </button>-->
                <button
                  v-if="seasons?.length && playerRef.isFullscreen()"
                  class="tv_player__right_buttons__ml60"
                  @click.stop="showSeasonsModal = !showSeasonsModal"
                >
                  <Icon icon="player/layers" />
                </button>
                <button
                  class="tv_player__right_buttons__ml60"
                  @click.stop="onTogglePictureInPicture"
                >
                  <Icon
                    :icon="state.isInPictureInPicture ? 'player/picture_in_picture_non' : 'player/picture_in_picture'"
                  />
                </button>
                <button
                  class="tv_player__right_buttons__ml60"
                  @click.stop="onToggleFullScreen"
                >
                  <Icon :icon="state.isFullscreen ? 'player/full_screen_non' : 'player/full_screen'" />
                </button>
              </div>
            </div>

            <div class="tv_player__progress_container flex">
              <div
                class="tv_player__progress_bar pointer"
                @mouseover="showSeekIndicator = true"
                @mouseleave="showSeekIndicator = false"
                @mousemove="onDisplaySeekTime"
                @click.stop="onSeek"
              >
                <div
                  class="tv_player__progress"
                  :style="{ width: progressPercentage }"
                />

                <div
                  v-show="showSeekIndicator"
                  class="tv_player__time_container flex"
                >
                  <div>{{ displayBegin }}</div>
                  <div
                    :style="seekTimeStyle"
                    class="tv_player__progress_indicator"
                  >
                    {{ seekTime }}
                  </div>
                  <div style="margin-left: auto">
                    {{ displayEnd }}
                  </div>
                </div>
              </div>
            </div>

            <div ref="seasonModal"></div>
            <SeasonsModal
              v-if="showSeasonsModal && seasons?.length"
              :seasons=seasons
              :teleport-to="seasonModal"
              @close="showSeasonsModal = false"
            />

          </div>
        </Transition>
      </template>
    </VideoPlayer>
    <div
      v-else
      class="tv_player__static-poster"
      :style="playerPosterStyle"
    >
      <template v-if="errorMessage">
        <div class="tv_player__static-poster__hint">
          {{ errorMessage }}
        </div>
      </template>
      <template v-else-if="plStream?.status > 0">
        <div class="tv_player__static-poster__hint">
          {{ plStream.status_description }}
        </div>
      </template>
      <template v-else-if="!isAuthenticated">
        <NuxtLink
          :to="{ hash: '#login' }"
          replace
          class="button-main"
        >
          Войти
        </NuxtLink>
        <div class="tv_player__static-poster__hint">
          Вы можете начать просмотр только после авторизации. Пожалуйста, нажмите кнопку «Войти» и введите свой логин и
          пароль, выданные Вашим оператором связи.
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { VideoPlayer } from '@videojs-player/vue';
import { format, intervalToDuration } from 'date-fns';
import Icon from '@/components/common/icons/icon.vue';
import SeasonsModal from '@/components/ui/Seasons/SeasonsModal.vue';

// ms
const LIVE_PAUSE_REWIND = 20000;
const ARCH_PAUSE_REWIND = 10000;
const SHOW_CONTROLS_TIMEOUT = 3000;
const STATE_PERSIST_KEY = 'player';

// timeoutid for diplay controls
let showControlsTimeout = null;
let hidingControls = false;
// live was paused
let pausedOnce = false;
// block onSeek attempts while seeking = true;buffering
let seeking = false;

// рендерим в app.vue в скрытом режиме
// todo: перезапрос плейлиста по таймеру (чтобы после просыпания компа продолжалось видео)
const playerStore = usePlayerStore();
const { plMode, plStream, plPoster, plBegin, plEnd, plPosition, plError,  plAutoplay, seasons } = storeToRefs(usePlayerStore());
const { isMiniPlayer, playingVodPath } = storeToRefs(usePlayerStore());

/*
  seek event: (timestamp)
*/
const emit = defineEmits(['seek']);

// current position
const position = shallowRef(0);
const displayBegin = computed(() => {
  if (['archive', 'live'].includes(plMode.value)) {
    return format(plBegin.value, 'HH:mm');
  }
  if (plMode.value === 'vod') {
    return tFormat(plBegin.value);
  }
  return plBegin.value;
});
const displayEnd = computed(() => {
  if (['archive', 'live'].includes(plMode.value)) {
    return format(plEnd.value, 'HH:mm');
  }
  if (plMode.value === 'vod') {
    return tFormat(plEnd.value);
  }
  return plEnd.value;
});
const displayPosition = computed(() => {
  if (['archive', 'live'].includes(plMode.value)) {
    return format(plBegin.value + position.value, 'HH:mm');
  }
  return tFormat(position.value);
});
const progressPercentage = computed(() => (position.value / (plEnd.value - plBegin.value)) * 100 + '%');

const { isAuthenticated } = storeToRefs(useUserStore());
const route = useRoute();

const showSeekIndicator = shallowRef(false);
const seekTime = shallowRef(0);
const seekTimeStyle = shallowRef({});
const playerState = shallowReactive({ muted: false });
const playerRef = shallowRef(null);
const showControls = shallowRef(true);
const errorMessage = shallowRef(null);
const poster = shallowRef(null);
const showSeasonsModal = shallowRef(false);
const seasonModal = shallowRef(null);
const src = computed(() => {
  if (!plStream?.value?.url) {
    return null;
  }
  if (!String(plStream?.value?.url).trim().length) {
    return null;
  }
  return plMode.value === 'archive' ? transformStreamUrl(plStream.value.url) : plStream?.value?.url;
});
const playerPosterStyle = computed(() => (plPoster.value ? { backgroundImage: `url(${plPoster.value})` } : {}));

onMounted(() => {
  let playerSavedState = sessionStorage.getItem(STATE_PERSIST_KEY);
  try {
    playerSavedState = JSON.parse(playerSavedState);
    Object.assign(playerState, playerSavedState);
  } catch {}
});
watch(playerState, () => {
  sessionStorage.setItem(STATE_PERSIST_KEY, JSON.stringify(playerState));
});
watchEffect(() => {
  if (playerRef.value === null) {
    return;
  }
  playerRef.value.currentTime(plPosition.value);
});
watchEffect(() => {
  errorMessage.value = plError.value;
  position.value = plPosition.value;
});
const closeMiniPlayer = () => playerStore.resetPlayerState();
function onPlay() {
  poster.value = null;
  if (plMode.value === 'live' && pausedOnce) {
    pausedOnce = false;
    emit('seek', Date.now() - LIVE_PAUSE_REWIND);
  }
  if (plMode.value === 'archive' && pausedOnce) {
    pausedOnce = false;
    emit('seek', plBegin.value + position.value - ARCH_PAUSE_REWIND);
  }
}
function onPause() {
  pausedOnce = true;
  poster.value = plPoster.value;
}
async function onPlToggle() {
  if (playerRef.value.paused()) {
    playerRef.value.play();
  } else {
    playerRef.value.pause();
    playerStore.addToPause(playerRef.value.currentTime());
  }
}
function onTimeUpdate() {
  let currentPosition = 0;
  const now = Date.now();
  seeking = false;
  switch (plMode.value) {
    case 'live':
      currentPosition = now - plBegin.value;
      break;
    case 'archive':
      currentPosition = plPosition.value + playerRef.value.currentTime() * 1000;
      break;
    case 'vod':
      currentPosition = playerRef.value.currentTime() * 1000;
      break;
  }

  // to next program while playing in TV mode
  if (plMode.value !== 'vod' && currentPosition + plBegin.value >= plEnd.value) {
    emit('seek', plBegin.value + currentPosition);
  }

  position.value = currentPosition;
}
function onError() {
  errorMessage.value = playerRef.value.error()?.message;
  playerRef.value.pause();
}
function onPlMounted({ player }) {
  playerRef.value = player;
}
// function onOpenSettings() {
//   console.log('open Settings...');
// }
function onTogglePictureInPicture() {
  if (!isMiniPlayer.value) {
    playingVodPath.value = route.path;
    // navigateTo('/');
  }
  if (isMiniPlayer.value && playingVodPath.value !== route.path) {
    playerStore.currentTime = playerRef.value.currentTime();
    poster.value = plPoster.value;
    playerStore.addToPause(playerRef.value.currentTime());
    navigateTo(playingVodPath.value);
  }
  isMiniPlayer.value = !isMiniPlayer.value;
}

function onToggleFullScreen() {
  if (!playerRef.value.isFullscreen()) {
    playerRef.value.requestFullscreen();
  } else {
    playerRef.value.exitFullscreen();
  }
}
function onFullScreenChange() {
  if (showSeasonsModal.value) {
    showSeasonsModal.value = !showSeasonsModal.value;
  }
}
async function onSeek(event) {
  if (seeking) {
    return;
  }
  seeking = true;
  const { time } = calcSeek(event);
  if (plMode.value === 'vod') {
    position.value = time;
    playerRef.value.currentTime(time / 1000);
    return;
  }

  emit('seek', plBegin.value + time);
}
function onSeeked() {
  seeking = false;
}

function onEnded() {
  console.log('video ended');
  if (plMode.value === 'vod' && seasons.value.length) {
    console.log('seasons:', seasons.value);
  }
}
function onExitFullWindow() {
  // need for close modal with episodes. instead of onFullScreenChange
  console.log('onExitFullWindow is working!!!');
}
function onDisplaySeekTime(event) {
  const { left, time } = calcSeek(event);
  if (plMode.value === 'vod') {
    seekTime.value = tFormat(time);
  } else {
    seekTime.value = format(plBegin.value + time, 'HH:mm');
  }

  seekTimeStyle.value = { left: `${left * 100}%` };
}
function onToggleMute() {
  playerState.muted = !playerRef.value.muted();
  playerRef.value.muted(!playerRef.value.muted());
}
function onGoLive() {
  pausedOnce = false;
  emit('seek', Date.now());
}
function onMouseover() {
  if (hidingControls) {
    return;
  }
  showControls.value = true;
  if (showControlsTimeout) {
    clearTimeout(showControlsTimeout);
    showControlsTimeout = null;
  }
  onMouseleave();
}
function onMouseleave() {
  showControlsTimeout = setTimeout(hideControls, SHOW_CONTROLS_TIMEOUT);
}
function onClickVideo() {
  if (hidingControls) {
    hidingControls = false;
    onMouseover();
  } else {
    hidingControls = true;
    hideControls();
  }
}
/**
 * hide controls & clear timeout
 */
function hideControls() {
  if (showSeasonsModal.value) return;
  showControls.value = false;
  if (showControlsTimeout !== null) {
    clearTimeout(showControlsTimeout);
    showControlsTimeout = null;
  }
}

function calcSeek(event) {
  const { width } = event.currentTarget.getBoundingClientRect();
  const { offsetX } = event;

  const left = offsetX / width;
  const time = (plEnd.value - plBegin.value) * left;
  return { left, time };
}
function tFormat(interval) {
  const duration = intervalToDuration({ start: 0, end: interval });
  let val = [];
  const push = (u, required = true) => {
    if (duration[u]) {
      val.push(duration[u] < 10 ? '0' + duration[u] : duration[u]);
    } else if (required) {
      val.push('00');
    }
  };

  push('hours', false);
  push('minutes');
  push('seconds');
  return val.join(':');
}
function transformStreamUrl(_streamUrl) {
  let streamUrl = _streamUrl;
  const match = streamUrl.match(/([^\/]+)\/mono\-([0-9]+)\-now\.m3u8\?token\=(.*)/);
  if (match) {
    const utcTimestamp = Math.floor(Date.now() / 1000);
    const timeToStartFrom = utcTimestamp - parseInt(match[2]);
    streamUrl = streamUrl.replace(/mono\-([0-9]+)\-now/, `mono-timeshift_rel-${timeToStartFrom}`);
  }

  const match2 = streamUrl.match(/([^?]+)\?token\=(.*)/);
  if (match2) streamUrl = `${streamUrl}.${Math.round(Math.random() * 10000000)}`;

  return streamUrl;
}
</script>
