export const usePlayerStore = defineStore('playerStore', () => {
  // initial state on main page
  const isMiniPlayer = shallowRef(true);
  const showPlayer = shallowRef(false);

  // refs to div
  const miniPlayerRef = shallowRef(null);
  const vodPageRef = shallowRef(null);

  const playingVodPath = shallowRef(null);

  // player state:
  const plMode = shallowRef('vod'); // String
  const plStream = shallowRef({}); // Object: response from channel/url OR video/<id>/stream
  const plPoster = shallowRef(null); // String: null for prevent "blinking" if poster not in cache yet
  const plBegin = shallowRef(0); // Number: js format (milliseconds) full timestamp
  const plEnd = shallowRef(0); // Number: js format (milliseconds) full timestamp
  const plPosition = shallowRef(0); // Number: js format (milliseconds) full plBegin
  const plError = shallowRef(null); // String
  const plAutoplay = shallowRef(true); // Boolean
  const seasons = shallowRef([]); // Array
  const contentId = shallowRef(null);

  // function setPlMode(mode) {
  //   if (['vod', 'archive', 'live'].includes(mode)) plMode.value = mode;
  // }

  const pausedStore = usePausedListStore();
  async function addToPause(pause_time) {
    const options = {};
    if (plMode.value === 'vod') {
      options.film_id = contentId.value;
      options.pause_time = Math.round(pause_time);
    } else {
      // todo: добавить для TV, взять из [...arg].vue
    }
    await pausedStore.addToPause(options);
  }
  
  function resetPlayerState() {
    isMiniPlayer.value = true;
    showPlayer.value = false;

    plMode.value = 'vod';
    plStream.value = {};
    plPoster.value = null;
    plBegin.value = 0;
    plEnd.value = 0;
    plPosition.value = 0;
    plError.value = null;
    plAutoplay.value = true;
    seasons.value = [];
    contentId.value = null;
  }

  return {
    // control state:
    isMiniPlayer,
    showPlayer,

    miniPlayerRef,
    vodPageRef,

    playingVodPath,

    // player state:
    plMode,
    plStream,
    plPoster,
    plBegin,
    plEnd,
    plPosition,
    plError,
    plAutoplay,
    seasons,
    contentId,

    // func
    // setPlMode,
    addToPause,
    resetPlayerState,
  };
});
