<template>
  <transition
    name="fade"
    v-bind="$attrs"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'FadeTransition',
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
