<template>
  <div class="main-layout">
    <div class="main-layout__wrapper">
      <header class="main-layout__header">
        <header-layout />
      </header>

      <main class="main-layout__main-wrapper container">
        <div class="main-layout__main">
          <div class="views-error">
            <div class="views-error__info">
              <h1 class="views-error__title">
                {{ errorText.title }}
              </h1>
              <p class="views-error__text">
                {{ errorText.text }}
              </p>
              <v-button
                class="views-error__button"
                primary
                @click="back"
              >
                {{ buttonText }}
                <template #prepend>
                  <v-icon
                    name="chevronLeft"
                    size="24"
                  />
                </template>
              </v-button>
              <p class="views-error__note">Вы можете написать в службу поддержки</p>
              <a
                href="mailto:support@moovi-tv.ru"
                class="views-error__link"
              >
                Support@moovi-tv.ru
              </a>
            </div>
            <div class="views-error__image-wrapper">
              <img
                v-if="type === '404'"
                class="views-error__image"
                src="./assets/images/error404Desktop.png"
                alt=""
              />
              <img
                v-if="type === '500'"
                class="views-error__image"
                src="./assets/images/error500Desktop.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </main>

      <footer class="main-layout__footer">
        <footer-layout />
      </footer>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import VButton from '@/components/common/VButton.vue';
import VIcon from '@/components/common/VIcon.vue';
import HeaderLayout from '@/components/common/TheHeader.vue';
import FooterLayout from '@/components/common/TheFooter.vue';
import { useMediaQueries } from '@/composables/useMediaQueries';
import { useRouter } from 'vue-router';

export default {
  name: 'ViewsError',
  components: { VButton, VIcon, HeaderLayout, FooterLayout },
  props: {
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    // const pageError = useError();
    // const error = ref(pageError.value);

    // watchEffect(() => {
    //   if (pageError.value) {
    //     console.log('error123', pageError.value);
    //   }
    // });

    const router = useRouter();
    const mediaQueries = useMediaQueries();
    const type = computed(() => props.error.statusCode);

    const errorText = computed(() => {
      const code = Number(props.error.statusCode);
      let data = props.error.data;

      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch {}
      }

      const message = data?.message;

      switch (code) {
        case 500:
          return {
            title: 'Ошибка сервера',
            text: message || 'Наши люди уже занимаются этим',
          };
        case 401:
          return {
            title: 'Нужна авторизация',
            text: message || 'Пожалуйста, войдите в личный кабинет',
          };
        default:
          return {
            title: 'Страница не найдена',
            text: message || 'Возможно ее кто-нибудь похитил...',
          };
      }
    });

    const buttonText = computed(() => {
      return mediaQueries.value.phone ? 'Назад' : 'Вернуться назад';
    });

    const back = () => {
      router.push('/');
    };

    return {
      errorText,
      buttonText,
      back,
      type,
    };
  },
};
</script>

<style lang="scss">
.views-error {
  display: flex;
  column-gap: 5px;
  width: 100%;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 187px;

  @include tablet {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 112px;
  }

  @include phone {
    margin-top: 0;
    margin-bottom: 80px;
  }

  &__info {
    background: $--black-200;
    border-radius: $--border-radius-medium-large;
    padding: 83px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 470px;

    @include tablet {
      order: 2;
      padding: 40px 10px;
      min-width: 440px;
      margin-top: 40px;
    }

    @include phone {
      min-width: calc(100% - 60px);
      padding: 32px 10px;
      margin-top: 24px;
    }
  }

  &__title {
    font-weight: $--font-weight-bold;
    font-size: $--font-size-larger-title;
    line-height: 38px;
    margin-bottom: 16px;

    @include tablet {
      font-weight: $--font-weight-medium;
      font-size: $--font-size-subtitle;
      line-height: 28px;
    }

    @include phone {
      font-size: $--font-size-subtitle-phone;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }

  &__text {
    font-weight: $--font-weight-regular;
    font-size: $--font-size-subtitle-phone;
    line-height: 32px;
    color: $--white-05percent;
    margin-bottom: 32px;
    padding: 0 35px;

    @include tablet {
      font-size: $--font-size-subtitle-phone;
      line-height: 14px;
    }

    @include phone {
      font-size: $--font-size-extra-small;
      margin-bottom: 16px;
    }
  }

  &__note,
  &__link {
    line-height: 16px;
    color: $--white-05percent;
    font-size: $--font-size-base;

    @include phone {
      font-size: $--font-size-extra-small;
      line-height: 14px;
    }
  }

  &__note {
    font-weight: $--font-weight-regular;
    margin: 32px 0 8px 0;

    @include phone {
      margin: 16px 0 2px 0;
    }
  }

  &__link {
    font-weight: $--font-weight-medium;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    width: 640px;

    @include tablet {
      width: 358px;
    }

    @include phone {
      width: 224px;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: cover;

    @include tablet {
      order: 1;
    }
  }

  &__button {
    @include phone {
      font-weight: $--font-weight-medium;
      font-size: $--font-size-subtitle-phone;
      line-height: 21px;
      border-radius: $--border-radius-large;
      height: 64px;
      width: 190px;
    }
  }
}
</style>
