<template>
  <v-dialog v-model:visible="localVisible">
    <template #content>
      <div class="create-profile-dialog">
        <header class="create-profile-dialog__header create-profile-dialog__row">
          <h1 class="create-profile-dialog__title flex justify-between">
            <span>{{ data?.id ? 'Редактирование' : 'Создание' }} профиля</span>
            <button
              class="create-profile-dialog__close"
              type="button"
              aria-label="Закрыть"
              @click="closeDialog"
            >
              <v-icon
                name="cross"
                size="24"
                title="Закрыть"
              />
            </button>
          </h1>
        </header>

        <validation-observer v-slot="{ errors, handleSubmit }">
          <v-form @submit.prevent="handleSubmit(updateProfile)">
            <template #default>
              <div class="create-profile-dialog__main">
                <v-form-row>
                  <v-form-field
                    :error-message="errors?.title"
                    class="create-profile-dialog__profile-name"
                  >
                    <v-input
                      :model-value="model.title"
                      type="text"
                      :is-error="errors?.title"
                      name="title"
                      rules="required"
                      placeholder="Придумайте название профиля"
                      @update:model-value="updatePropertySection('title', $event)"
                    />
                  </v-form-field>
                </v-form-row>
                <v-form-row>
                  <div class="create-profile-dialog__info">
                    <p>Выберите аватар профиля.</p>
                    <p>Заменить его можно в любой момент в Настройках.</p>
                  </div>
                  <show-avatars
                    :active-url="model.icon"
                    @update:active-url="updatePropertySection('icon', $event)"
                  />
                </v-form-row>
                <v-form-row>
                  <div class="create-profile-dialog__info">
                    <p>Выберите возрастное ограничение профиля.</p>
                  </div>
                  <v-group-buttons
                    :options="ageRatingOptions"
                    :active-value="!model.age_rating ? 0 : model.age_rating"
                    @update:value="updatePropertySection('age_rating', $event)"
                  />
                </v-form-row>
                <v-form-row>
                  <v-switch
                    class="create-profile-dialog__switch-limit"
                    :checked="limitViewingDuration"
                    label="Ограничение длительности просмотра"
                    @update:checked="limitViewingDuration = $event"
                  />
                  <v-group-buttons
                    v-if="limitViewingDuration"
                    :options="timeLimitOptions"
                    :active-value="activeTimeLimitOptions"
                    @update:value="updatePropertySection('time_limit', $event)"
                  />
                </v-form-row>
              </div>
            </template>
            <template #footer>
              <footer class="create-profile-dialog__buttons-wrapper">
                <div class="flex justify-between">
                  <button
                    type="button"
                    class="channel_page__btn create-profile-dialog__button-cancel"
                    primary
                    @click="closeDialog"
                  >
                    Отменить
                  </button>
                  <button
                    type="submit"
                    class="channel_page__btn create-profile-dialog__button selected"
                    primary
                    success-text
                  >
                    Сохранить
                  </button>
                </div>
                <div v-if="model.id && model.is_custom">
                  <hr />
                  <button
                    type="button"
                    class="channel_page__btn create-profile-dialog__button create-profile-dialog__button-deleted"
                    primary
                    @click="deletedProfile"
                  >
                    Удалить профиль "{{ data?.title }}"
                  </button>
                </div>
              </footer>
            </template>
          </v-form>
        </validation-observer>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VInput from '@/components/common/VInput.vue';
import VDialog from '@/components/common/VDialog.vue';
import VIcon from '@/components/common/VIcon.vue';
import ShowAvatars from '@/components/Profile/ShowAvatars.vue';
import VGroupButtons from '@/components/common/VGroupButtons.vue';
import VSwitch from '@/components/common/VSwitch.vue';
import { Form as ValidationObserver } from 'vee-validate';
import { alertService } from '@/services/alert';

const props = defineProps({
  visible: { type: Boolean, default: false },
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const userStore = useUserStore();
const { settings } = storeToRefs(useSettingsStore());
const customerInfoStore = useCustomerInfoStore();

const model = computed(() => props.data);

// Возрастное ограничение
const ageRatingOptions = computed(() => settings.value?.CLIENT_SETTINGS?.profiles_age_ratings || []);
// Ограничение длительности просмотра
const timeLimitOptions = computed(() => {
  if (!settings.value?.CLIENT_SETTINGS?.profiles_time_limits) {
    return [];
  }
  return settings.value?.CLIENT_SETTINGS?.profiles_time_limits.slice(1);
});

const limitViewingDuration = ref(0);

watchEffect(() => {
  limitViewingDuration.value = !!model.value?.time_limit;
});

const activeTimeLimitOptions = computed(() => (!!!model.value.time_limit ? 60 : model.value.time_limit));

const emits = defineEmits(['on:visible', 'on:update-profile', 'on:deleted']);

const localVisible = computed({
  get: () => props.visible,
  set: val => {
    emits('on:visible', val);
  },
});

const updateProfile = async () => {
  if (!model.value.icon) {
    const icons = settings.value?.CLIENT_SETTINGS?.profiles_icons_urls;
    model.value.icon = icons ? icons[0] : '';
  }

  if (!limitViewingDuration.value) {
    model.value.time_limit = undefined;
  }

  const data = {
    ...model.value,
    showUnsubscribed: 1,
    showHint: 0,
    pin: '0000',
  };

  try {
    await (data.id ? customerInfoStore.updateProfile(data) : customerInfoStore.addProfile(data));
  } catch (error) {
    alertService.error({ text: error, isIconCross: true });
    return;
  }

  emits('on:update-profile', model.value);
  closeDialog();
};

const closeDialog = () => {
  localVisible.value = false;
};

const updatePropertySection = (key, value) => {
  model.value[key] = value;
};

const deletedProfile = async () => {
  if (!model.value.id) {
    return;
  }

  try {
    await customerInfoStore.deleteProfile(model.value.id);
  } catch (error) {
    alertService.error({ text: error.message, isIconCross: true, timeout: 4500 });
    return;
  }  

  alertService.success({ text: 'Профиль удалён', isIconCross: true, timeout: 4500 });

  emits('on:deleted', model.value.id);

  closeDialog();
};
</script>

<style lang="scss">
.create-profile-dialog {
  padding: 50px 48px 60px;

  @include phone {
    padding: 0 18px;
  }

  &__row {
    margin-bottom: 40px;
  }

  &__info {
    color: $--white-05percent;
    margin-bottom: 25px;
    text-align: center;
  }

  &__title {
    font-size: 20px;
    font-weight: $--font-weight-bold;
    color: $--white;
  }

  &__close {
    cursor: pointer;
    color: $--white-05percent;

    &:hover {
      color: $--white;
    }

    @include phone {
      top: 16px;
      right: 16px;
    }
  }

  &__profile-name {
    .v-input {
      background-color: white;
      input {
        color: var(--primary-text-field-background);
        &::placeholder {
          color: $--light-gray-05percent;
          font-style: italic;
        }
      }
    }
  }

  &__buttons-wrapper {
    @include phone {
      flex-direction: column-reverse;
      row-gap: 8px;
    }
    button {
      margin: 0;
      height: auto;
      width: auto;
      padding: 10px 40px;
      font-size: $--font-size-larger;
      border-radius: $--border-radius-extra-small;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__select {
    width: 45%;
  }

  &__button-cancel {
    border: 1px solid white;
  }

  hr {
    margin: 30px 0;
  }

  &__button-deleted {
    min-width: 100%;
    border: 1px solid $--light-gray-01percent;
    color: $--light-gray-01percent;
    background-color: transparent;
    &:hover {
      color: $--white;
    }
  }

  &__switch-limit {
    margin-bottom: 20px;
    width: 100%;
    .v-switcher__wrapper {
      justify-content: space-between;
    }
  }
}
</style>
