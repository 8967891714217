export const MAX_DEVICES_COUNT = 5;

export const ACCOUNT_TYPE_FIZ = 'fiz';

export const ACCOUNT_TYPE_UR = 'ur';

export const DEFAULT_AUTH_METHODS = {
 LOGIN: 'login',
 PHONE: 'phone',
};

export const MAIN_PROFILE_VALUES = {
 '0': false,
 '1': true,
};

export const SKEY = 'U2FsdGVkX1+ebjPiEKdecsx7/KtcWx1r/Zkc+SoRHeg=';