<template>
  <div class="show-avatars">
    <div class="show-avatars__avatar-main">
      <img
        :src="activeIconUrl"
        alt="Аватар профиля"
      />
    </div>
    <div class="flex justify-center">
      <div class="show-avatars__icons">
        <div
          v-for="(row, rowIndex) in iconRows"
          :key="'row-' + rowIndex"
          class="show-avatars__icons-row"
        >
          <img
            v-for="(icon, index) in row"
            :key="'icon-' + index"
            :src="icon"
            :class="{ active: activeIconIndex === index + rowIndex * 5 }"
            @click="selectIcon(index + rowIndex * 5)"
            class="show-avatars__icons-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  activeUrl: {
    type: String,
    default: '',
  },
});

const { settings } = storeToRefs(useSettingsStore());

const activeIconIndex = ref(0);

const icons = computed(() => settings.value?.CLIENT_SETTINGS?.profiles_icons_urls);

watchEffect(() => {
  if (icons.value.indexOf(props.activeUrl) > -1) {
    activeIconIndex.value = icons.value.indexOf(props.activeUrl);
  }
});

const activeIconUrl = computed(() => (icons.value ? icons.value[activeIconIndex.value] : ''));

const emits = defineEmits(['update:activeIndex', 'update:activeUrl']);

const selectIcon = index => {
  activeIconIndex.value = index;
  const url = icons.value[index];
  emits('update:activeIndex', index);
  emits('update:activeUrl', url);
};

const iconRows = computed(() => {
  let rows = [];
  for (let i = 0; i < icons.value.length; i += 5) {
    rows.push(icons.value.slice(i, i + 5));
  }
  return rows;
});
</script>

<style lang="scss">
.show-avatars {
  &__title {
    color: $--white;
    text-align: center;
    margin-bottom: 20px;
  }

  &__icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-item {
      width: 40px;
      height: 40px;
      border-radius: $--border-radius-extra-small;
      cursor: pointer;
      &.active {
        border: 3px solid $--white;
      }
    }
  }

  &__avatar-info {
    color: $--white-05percent;
    margin-bottom: 25px;
    text-align: center;
  }

  &__avatar-main {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    height: 70px;
    img {
      width: 70px;
      height: 70px;
      border-radius: $--border-radius-extra-small;
    }
  }
}
</style>
