export const appHead = {"meta":[{"name":"viewport","content":"width=720"},{"charset":"utf-8"}],"link":[{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-Regular.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-Medium.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-SemiBold.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-Bold.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-ExtraBold.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/GolosText/GolosText-Black.woff2"}],"style":[{"children":"@font-face{font-family:\"GolosText\";font-weight:400;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-Regular.woff2') format('woff2');}@font-face{font-family:\"GolosText\";font-weight:500;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-Medium.woff2') format('woff2');}@font-face{font-family:\"GolosText\";font-weight:600;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-SemiBold.woff2') format('woff2');}@font-face{font-family:\"GolosText\";font-weight:700;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-Bold.woff2') format('woff2');}@font-face{font-family:\"GolosText\";font-weight:800;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-ExtraBold.woff2') format('woff2');}@font-face{font-family:\"GolosText\";font-weight:900;font-style:normal;font-display:swap;src:url('/fonts/GolosText/GolosText-Black.woff2') format('woff2');}"}],"script":[],"noscript":[],"viewport":"width=720","description":"Более 250 телеканалов и 2 онлайн-кинотеатра"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'