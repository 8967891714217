<template>
  <VDialog
    :visible="true"
    :size="widthDialog"
    :teleport-to="teleportTo"
    @close="$emit('close')"
  >
    <template #content>
      <div class="seasons_modal__content">
        <button
          class="seasons_modal__cross"
          type="button"
          aria-label="Close"
          @click="$emit('close')"
        >
          <Icon icon="pins/pin_close" />
        </button>
        <SeasonSwiper
          :content="seasons"
          :current-season="currentSeasonId"
          @set-season="setSeason"
        />
        <div class="epg_dialog__epg_list_container">
          <div
            v-for="(episode, index) in episodes"
            :key="episode.id"
          >
            <SeasonsCard
              :episode="episode"
              :class="{ selected: currentSeasonId === episode.seasonId && episodeIndex === index }"
              class="season_card"
            />
          </div>
        </div>
      </div>
    </template>
  </VDialog>
</template>

<script setup>
import VDialog from '@/components/common/VDialog.vue';
import SeasonSwiper from '@/components/ui/Swipers/SeasonSwiper.vue';
import SeasonsCard from '@/components/ui/Seasons/SeasonsCard.vue';
import Icon from '@/components/common/icons/icon.vue';

const props = defineProps({
  seasons: {
    type: Array,
    default: () => [],
  },
  teleportTo: {
    // type: HTMLDivElement,
    default: null,
  },
});
defineEmits(['close']);

const episodes = shallowRef([]);

const setSeason = (season) => episodes.value = season?.episodes || [];

const route = useRoute();
const currentSeasonId = ref(0)
const episodeIndex = ref(0)
const seasonIndex = ref(0)
if (process.client) {
  onMounted(async () => {
    setSeason(props.seasons[0]);

    if (Array.isArray(props.seasons)) {
      props.seasons.forEach((season, indexSeason) =>
        season.episodes.forEach((series, indexSeries) => {
          props.seasons[indexSeason].episodes[indexSeries]['seasonId'] = season.id
          if (series.id == route.params.name) {
            episodes.value = props.seasons[indexSeason].episodes || [];

            currentSeasonId.value = season.id || 0;
            seasonIndex.value = indexSeason;
            episodeIndex.value = indexSeries;
          }
        })
      );
    }
  });
}

const widthDialog = computed(() => {
  if(process.server) return null;
  const windowWidth = window.innerWidth;
  return windowWidth <= 720 ? 'large' :
    windowWidth <= 1024 ? 'large' :
    windowWidth <= 1280 ? 'large' :
    windowWidth <= 1440 ? 'large' :
    windowWidth <= 1920 ? 'large-plus' : 'extra-large';
});
</script>
