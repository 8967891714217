import revive_payload_client_4sVQNw7RlN from "/opt/web_client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/web_client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/web_client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/web_client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/web_client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/web_client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/web_client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/web_client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/web_client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _0_error_handlers_gUUqIVvB41 from "/opt/web_client/plugins/0-error-handlers.js";
import _1_init_client_0lEwqz3jY7 from "/opt/web_client/plugins/1-init.client.js";
import directive_click_outside_yxi9O9AxAk from "/opt/web_client/plugins/directive.click-outside.js";
import floating_8Pbpomv3Tz from "/opt/web_client/plugins/floating.js";
import timers_client_dV1Ni73A22 from "/opt/web_client/plugins/timers.client.js";
import veeValidate_nOzy97eFIK from "/opt/web_client/plugins/veeValidate.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _0_error_handlers_gUUqIVvB41,
  _1_init_client_0lEwqz3jY7,
  directive_click_outside_yxi9O9AxAk,
  floating_8Pbpomv3Tz,
  timers_client_dV1Ni73A22,
  veeValidate_nOzy97eFIK
]