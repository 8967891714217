<template>
  <div class="alert-group">
    <transition-group name="list">
      <v-alert
        v-for="alert in alerts"
        :key="alert.id"
        class="v-alert"
        v-bind="alert"
        @close="close"
      />
    </transition-group>
  </div>
</template>

<script>
import { alertService, alerts } from '@/services/alert';
import VAlert from './VAlert.vue';

export default {
  name: 'VAlertGroup',
  components: { VAlert },

  computed: {
    alerts() {
      return alerts.list;
    },
  },
  methods: {
    close(id) {
      alertService.pop(id);
    },
  },
};
</script>

<style lang="scss">
.alert-group {
  position: fixed;
  width: min-content;
  top: 10px;
  right: 10px;
  z-index: 1300;

  .v-alert {
    transition: all 0.3s;
  }

  .list-leave-active {
    position: absolute;
  }

  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
