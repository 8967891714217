import { defineRule, configure } from 'vee-validate';
import { required, email, confirmed, min_value as minValue, min, between } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

export default defineNuxtPlugin(nuxtApp => {
  // Doing something with nuxtApp
  // export default function installVeeValidateRules() {
  defineRule('required', required);
  defineRule('min', min);
  defineRule('confirmed', confirmed);
  defineRule('email', email);
  defineRule('between', between);
  defineRule('minValue', minValue);

  configure({
    // Generates an English message locale generator
    generateMessage: localize('ru', {
      messages: {
        required: 'Это поле обязательно',
        email: 'Введите корректный email',
        between: 'Значение {field} должно находиться в диапазоне от 0:{min} and 1:{max}',
        min: `Длина пароля должна быть не менее 0:{min} символов`,
        confirmed: 'Пароль и подтверждение пароля не совпадают',
        minValue: `Значение должно быть не меньше 0:{min}`,
      },
    }),
  });
  // }
});
