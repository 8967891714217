export function toBoolean(val) {
  const negative = [false, 0, NaN, null, undefined, 'false', '0', 'null', 'undefined', 'off', -Infinity];
  const positive = [true, 1, 'true', '1', 'on', Infinity];

  if (negative.some(n => n === val)) {
    return false;
  }
  if (positive.some(p => p === val)) {
    return true;
  }
}
export function toNumber(val) {
  let num = Number(val);
  if (isNaN(num)) {
    num = 0;
  }
  return num;
}