<template>
  <a
    v-if="!disabled && href"
    class="button"
    :class="classes"
    :href="href"
    v-bind="$attrs"
    target="_blank"
  >
    <slot v-if="!icon" />
    <v-icon
      v-else
      :name="icon"
    />
  </a>
  <nuxt-link
    v-else-if="!disabled && to"
    class="button"
    :class="classes"
    v-bind="$attrs"
    :to="to"
  >
    <div
      v-if="$slots.prepend"
      class="button__append"
    >
      <slot name="prepend" />
    </div>
    <slot />
    <div
      v-if="$slots.append"
      class="button__append"
    >
      <slot name="append" />
    </div>
  </nuxt-link>
  <button
    v-else-if="icon"
    :disabled="disabled"
    class="button"
    type="button"
    v-bind="$attrs"
    :class="classes"
  >
    <v-icon :name="icon" />
  </button>
  <button
    v-else
    :disabled="disabled"
    class="button"
    type="button"
    v-bind="$attrs"
    :class="classes"
  >
    <div
      v-if="$slots.prepend"
      class="button__prepend"
    >
      <slot name="prepend" />
    </div>
    <slot />
    <div
      v-if="$slots.append"
      class="button__append"
    >
      <slot name="append" />
    </div>
  </button>
</template>

<script>
import VIcon from '@/components/common/VIcon.vue';

export default {
  name: 'VButton',
  components: {
    VIcon,
  },
  props: {
    square: { type: Boolean, default: false },
    icon: { type: String, default: null },
    primary: { type: Boolean, default: false },
    isText: { type: Boolean, default: false },
    to: { type: [String, Object], default: null },
    href: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    compressed: { type: Boolean, default: false },
    compressedTiny: { type: Boolean, default: false },
    compressedMedium: { type: Boolean, default: false },
    successText: { type: Boolean, default: false },
    errorText: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        'button--primary': this.primary,
        'button--square': this.square,
        'button--icon': this.icon,
        'button--text': this.isText,
        'button--disabled': this.disabled,
        'button--compressed': this.compressed,
        'button--compressed-tiny': this.compressedTiny,
        'button--compressed-medium': this.compressedMedium,
        'button--success': this.successText,
        'button--error': this.errorText,
        'button--secondary': this.secondary,
      };
    },
  },
};
</script>

<style lang="scss">
.button {
  $this: &;

  font-family: $--font-base;
  font-weight: $--font-weight-medium;
  font-size: $--font-size-subtitle;
  text-align: center;
  background-color: transparent;
  color: get-theme-for($text, 'secondary');
  padding: 22px 30px;
  height: 80px;
  border-radius: $--border-radius-large;
  transition: $--transition-duration;
  border: 2px solid get-theme-for($border-color, 'primary', 'enabled');
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &__prepend,
  &__append {
    display: flex;
    align-items: center;
  }

  &__prepend {
    margin-right: 10px;
    svg {
      color: $--white;
      path {
        fill: $--white;
      }
    }
  }

  &__append {
    margin-left: 10px;
  }

  &:hover,
  &:focus {
    border-color: get-theme-for($border-color, 'primary', 'hover');
  }

  &:active {
    border-color: get-theme-for($border-color, 'primary', 'pressed');
  }

  &--icon {
    border-radius: 20px;
    width: 48px;
    height: 48px;
    padding: 12px;
    color: get-theme-for($icon, 'active');
  }

  &--compressed {
    height: 68px;
    font-size: 18px;
    padding: 21px 25px;
    border-radius: $--border-radius-medium;

    &-tiny {
      height: 44px;
      padding: 10px 15px;
      border-radius: $--border-radius-small;
      font-size: 18px;
    }

    &-medium {
      height: 56px;
      border-radius: $--border-radius-large;
      font-size: 18px;
      padding: 12px 20px;
      font-weight: $--font-weight-regular;
    }
  }

  &--disabled {
    opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
      cursor: not-allowed;
      background-color: transparent;
      border-color: get-theme-for($border-color, 'primary', 'enabled');
    }
  }

  &--primary,
  &--secondary {
    color: get-theme-for($text, 'primary');
    border: none;
    transition: $--transition-duration;

    &#{$this}--error {
      color: get-theme-for($text, 'error');
    }
  }

  &--primary {
    background-color: get-theme-for($button, 'primary', 'enabled');

    &:hover {
      background-color: get-theme-for($button, 'primary', 'hover');
    }

    &:active {
      background-color: get-theme-for($button, 'primary', 'pressed');
    }

    &#{$this}--disabled {
      background-color: get-theme-for($button, 'primary', 'enabled');
      color: get-theme-for($text, 'disabled');
    }

    &#{$this}--success {
      color: get-theme-for($text, 'success');
    }
  }

  &--square {
    width: 80px;
    height: 80px;
  }

  &--text {
    display: inline;
    background: transparent;
    border: none;
    color: get-theme-for($text, 'primary');
    padding: 0;
    height: unset;
    font-weight: $--font-weight-regular;

    &:hover,
    &:focus {
      background: transparent;
      color: get-theme-for($text, 'primary');
    }

    &:active {
      color: get-theme-for($text, 'primary');
    }
  }

  &--secondary {
    background-color: get-theme-for($button, 'secondary', 'enabled');

    &:hover{
      background-color: get-theme-for($button, 'secondary', 'hover');
    }

    &:active {
      background-color: get-theme-for($button, 'secondary', 'enabled');
    }

    &#{$this}--disabled {
      background-color: get-theme-for($button, 'secondary', 'disabled');
      color: get-theme-for($text, 'disabled');
    }
  }
}
</style>
