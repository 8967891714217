<template>
  <div class="root-app">
    <NuxtLayout>
      <NuxtLoadingIndicator :color="'#F45E49'" />

      <div
        :class="{ miniPlayer: isMiniPlayer }"
        ref="miniPlayerRef"
      ></div>
      <template v-if="miniPlayerRef">
        <Teleport :to="isMiniPlayer ? miniPlayerRef : vodPageRef">
          <ClientOnly>
            <PlayerVod v-if="showPlayer" />
          </ClientOnly>
        </Teleport>
      </template>

      <NuxtPage />
      <v-alert-group />
    </NuxtLayout>
  </div>
  <Teleport to="body">
    <div
      v-if="generalStore.isEmergencyMode"
      v-scroll-lock="generalStore.isEmergencyMode"
    >
      <div class="lost-connection-with-server-overlay" />
      <div class="lost-connection-with-server-text">
        <p>Потеряна связь с сервером</p>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { useHead } from 'nuxt/app';
import { add as timerAdd, clean as timersClean } from '@/services/timer';
import { useGeneralStore } from '@/store/generalState';
import { useSettingsStore } from '@/store/settings';
import { useUserStore } from '@/store/user';
import { useCustomerInfoStore } from '@/store/customerInfo';
import vScrollLock from '@/directives/ScrollLock';
import VAlertGroup from '@/components/common/alerts/VAlertGroup.vue';
import PlayerVod from '@/components/ui/Players/PlayerVod.vue';
import { updateApiQuery } from '@/composables/useApi';
import { parseUserAgent } from '@/utils/util';

const generalStore = ref(false);
const settingsStore = useSettingsStore();
const userStore = useUserStore();
const customerStore = useCustomerInfoStore();
const { isMiniPlayer, showPlayer, miniPlayerRef, vodPageRef } = storeToRefs(usePlayerStore());

if (process.server) {
  const headers = useRequestHeaders();
  const userAgentString = headers?.['user-agent'];

  if (userAgentString) {
    const { browser, version } = parseUserAgent(userAgentString);

    updateApiQuery({
      model: browser,
      firmware: version,
    });
  }
}

useHead({
  title: 'MOOVI: ТВ, Кино, Сериалы',
});

if (process.client) {
  generalStore.value = useGeneralStore();
  window.addEventListener('beforeunload', timersClean);

  // timerAdd('fetch:settings', settingsStore.fetchSettings, 8 * 60 * 1000);

  // if(process.server) {
  // await settingsStore.fetchSettings()
  // }

  if (userStore.isAuthenticated) {
    await customerStore.fetchCustomer();
  }
}

// await useRecommendedListStore().fetchRecommended();

//TO DO как до рендеринга опрделить ширину экрана???????
const mediaParams = ref({
  phone: 0,
  largePhone: 481,
  tablet: 601,
  desktop: 1025,
});

provide('mq', mediaParams.value);
</script>

<style lang="scss">
.root-app {
  position: relative;
}

.lost-connection-with-server-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  opacity: 0.8;
  background-color: $--black;
}

.lost-connection-with-server-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000000;
  color: white;
  padding: 50px;
  background: #1c2227;
  border-radius: 32px;
  transform: translate(-50%, -50%);
}
</style>
