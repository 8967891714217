<template>
  <v-input
    v-model="localValue"
    :mask="$options.PHONE_MASK"
    placeholder="+7 (___) ___ - __ - __"
    :text-mask-config="$options.TEXT_MASK_CONFIG"
    inputmode="numeric"
    v-bind="$attrs"
    type="text"
  />
</template>

<script>
import VInput from '@/components/common/VInput.vue';
import { PHONE_MASK } from '@/constants/textMask';

const textMaskConfig = {
  guide: true,
  placeholderChar: '_',
};

export default {
  TEXT_MASK_CONFIG: textMaskConfig,
  PHONE_MASK,
  name: 'VInputPhone',
  components: { VInput },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      async set(value) {
        const notMaskValue = await value.replace(/[^\d]+/g, '').substr(0, 11);
        this.$emit('update:modelValue', notMaskValue);
      },
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.localValue = newValue;
      },
    },
  },
  methods: {},
};
</script>
