<template>
  <ResizeHandler />
  <div class="header">
    <div class="header__container container">
      <NuxtLink
        class="header__logo"
        to="/"
      >
        <VLogo />
      </NuxtLink>
      <!--    TODO: временное решение пока нет поведения шапки-->
      <div class="header__item-container">
        <NuxtLink
          v-if="!showSearch"
          v-for="link in LINKS"
          :key="link.name"
          class="header__item header__item_in-group"
          :to="{ name: link.name }"
        >
          {{ link.text }}
        </NuxtLink>
        <Icon
          v-if="!showSearch"
          :icon="'ui/search_white'"
          class="header__search_button pointer"
          @click="toggleSearch"
        />
        <VSearchBar
          v-if="showSearch"
          @close-search="closeSearch"
        />
      </div>
      <VButton
        is-text
        class="header__button"
        :to="isAuthenticated ? { name: 'personal-account' } : null"
        @click="isAuthenticated ? null : login()"
      >
        <VIcon
          name="userCircle"
          class="header__button-icon"
          size="20px"
        />
        <div class="header__button_text">
          {{ isAuthenticated ? 'Личный кабинет' : 'Войти' }}
        </div>
      </VButton>
    </div>
  </div>
  <div v-if="showDialogLogin">
    <VDialogAuth @close="hideLogin" />
  </div>
</template>

<script setup>
import VIcon from '@/components/common/VIcon.vue';
import Icon from '@/components/common/icons/icon.vue';
import VLogo from '@/components/common/VLogo.vue';
import VButton from '@/components/common/VButton.vue';
import VDialogAuth from '@/components/common/VAuthDialog.vue';
import VSearchBar from '@/components/ui/VSearchBar';
import ResizeHandler from '@/components/ui/ResizeHandler';

const route = useRoute();

const { isAuthenticated } = storeToRefs(useUserStore());

// const mediaQueries = useMediaQueries();

const LINKS = [
  { text: 'Главная', name: 'index' },
  { text: 'Телеканалы', name: 'channel' },
  { text: 'Кино', name: 'kino' },
];

const showDialogLogin = ref(false);
const showSearch = shallowRef(false);
const toggleSearch = () => (showSearch.value = !showSearch.value);
const closeSearch = () => (showSearch.value = false);

if (process.client) {
  watchEffect(() => {
    showDialogLogin.value = route.hash === '#login';
  });
}

const login = () => {
  navigateTo({ hash: '#login' }, { replace: true });
};

function hideLogin() {
  navigateTo({ hash: null }, { replace: true });
}
</script>

<style lang="scss">
@media screen and (max-width: 2560px) {
  .header__button {
    --btn-font: 1.5rem;
  } // 24px
  .header__item {
    --btn-font: 1.5rem;
  } // 24px
  //.header__button-icon { --icon-lk: 24px; }
  .header__search_button {
    width: 22px;
  }
  .header__container {
    height: 42px;
  }
}

@media screen and (max-width: 1920px) {
  .header__button {
    --btn-font: 1.25rem;
  } // 20px
  .header__item {
    --btn-font: 1.25rem;
  } // 20px
  //.header__button-icon { --icon-lk: 22px; }
  .header__search_button {
    width: 18px;
  }
}

@media screen and (max-width: 1440px) {
  .header__button {
    --btn-font: 0.875rem;
  } // 14px
  .header__item {
    --btn-font: 0.875rem;
  } // 14px
  //.header__button-icon { --icon-lk: 20px; }
  .header__search_button {
    width: 14px;
  }
  .header__container {
    height: 22px;
  }
}

@media screen and (max-width: 1280px) {
  .header__button {
    --btn-font: 0.875rem;
  } // 14px
  .header__item {
    --btn-font: 0.875rem;
  } // 14px
  //.header__button-icon { --icon-lk: 21px; }
}

@media screen and (max-width: 1024px) {
  .header__button {
    --btn-font: 0.8125rem;
  } // 13px
  .header__item {
    --btn-font: 0.8125rem;
  } // 13px
  //.header__button-icon { --icon-lk: 21px; }
}

@media screen and (max-width: 720px) {
  .header__button {
    --btn-font: 0.8125rem;
  } // 13px
  .header__item {
    --btn-font: 0.8125rem;
  } // 13px
  //.header__button-icon { --icon-lk: 21px; }
}

.header {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5%;
    margin-bottom: 50px;

    @include phone {
      margin-bottom: 45px;
    }
  }

  &__button {
    color: $--white;
    display: flex;
    align-items: center;
    font-weight: $--font-weight-regular;
    //font-size: $--font-size-larger;
    font-size: var(--btn-font);
    line-height: 150%;

    &:hover {
      &-icon {
        color: $--orange;
      }
    }
  }

  &__button-icon {
    //margin-right: 5px;
    color: $--white-73percent;
    //height: var(--icon-lk) !important;
    //width: var(--icon-lk) !important;

    @include phone {
      margin-right: 0;
    }
  }

  &__button_text {
    margin-left: 5px;
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__item {
    text-decoration: none;
    color: $--white;
    display: flex;
    align-items: center;
    font-weight: $--font-weight-regular;
    //font-size: $--font-size-larger;
    font-size: var(--btn-font);
    line-height: 150%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -3px;
      background: $--orange;
      transition: transform 0.5s;
    }

    &:not(:hover) {
      color: $--white-73percent;

      &::after {
        transform: scaleX(0);
      }
    }

    &_in-group {
      margin-right: 45px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__search_button {
    margin-left: 45px;
  }

  &__item-container {
    display: flex;
    align-items: center;

    @include tablet {
      // display: none;
    }
  }

  .router-link-active {
    color: $--white;

    .header__item-icon {
      color: $--white;
    }
  }
}
</style>
