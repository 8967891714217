<template>
  <VDialog
    :visible="true"
    size="large"
    @close="$emit('close')"
  >
    <template #content>
      <div class="profile-choosing">
        <header class="profile-choosing__header">
          <h1 class="profile-choosing__title">Введите PIN-код</h1>
          <p class="profile-choosing__subtitle">
            Для возврата в профиль "Основной" введите PIN-код
          </p>
        </header>
        <VForm
          class="profile-choosing__form"
          @submit.prevent="choosingProfile"
        >
          <template #default>
            <div class="profile-choosing__form-main">
              <VFormRow class="profile-choosing__form-row">
                <VFormField class="profile-choosing__form-field">
                  <VOtpInput
                    v-model="code"
                    :length="4"
                    :is-error="isError"
                    rules="required"
                    @submit="choosingProfile"
                  />
                </VFormField>
              </VFormRow>
            </div>
          </template>
          <template #footer>
            <footer class="profile-choosing__form-footer">
              <VButton
                type="submit"
                primary
                class="profile-choosing__form-button"
                @click="$emit('close')"
              >
                Отменить
              </VButton>
              <VButton
                type="submit"
                primary
                success-text
                class="profile-choosing__form-button"
                @click="choosingProfile"
              >
                Продолжить
              </VButton>
            </footer>
          </template>
        </VForm>
      </div>
    </template>
  </VDialog>
  <ProfilePinCodeChangesDialog
    v-if="showChangeCodeDialog"
    v-bind="{ profileName }"
  />
</template>

<script setup>
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VButton from '@/components/common/VButton.vue';
import VDialog from '@/components/common/VDialog.vue';
import VFormField from '@/components/form/VFormField.vue';
import ProfilePinCodeChangesDialog from '@/components/Profile/PinCodeChangesDialog.vue';
import VOtpInput from '@/components/common/VOtpInput.vue';
import { alertService } from '@/services/alert';

defineProps({
  profileName: { type: String, default: '' },
  visible: { type: Boolean, default: false },
});

const emit = defineEmits(['choosing', 'close']);

const code = shallowRef('');
const showChangeCodeDialog = shallowRef(false);
const isError = shallowRef(false);
const errorMessage = shallowRef(null);

function choosingProfile() {
  if (code.value) {
    emit('choosing', code.value);
    code.value = '';
    isError.value = false;
    errorMessage.value = null;
  } else {
    isError.value = true;
    alertService.error({ text: 'Введите Пин-код', isIconCross: true, timeout: 4500 });
  }
}
</script>

<style lang="scss">
.profile-choosing {
  max-width: 560px;
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding: 90px 48px 80px;

  @include phone {
    padding: 44px 16px 42px;
  }

  &__header {
    margin-bottom: 32px;
    text-align: center;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;
    color: $--white;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      font-weight: $--font-weight-medium;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--white-05percent;
    font-size: $--font-size-subtitle-phone;
    line-height: 21px;

    span {
      color: $--white;
      font-weight: $--font-weight-medium;
    }

    @include phone {
      font-size: $--font-size-small;
      line-height: 16px;
    }
  }

  &__form {
    &-main {
      margin-bottom: 76px;

      @include phone {
        margin-bottom: 59px;
      }
    }

    &-row {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      @include phone {
        margin-bottom: 8px;
      }
    }

    &-field {
      width: 335px;

      @include phone {
        margin-bottom: 16px;
        width: 240px;
      }
    }

    &-input {
      &::placeholder {
        font-weight: $--font-weight-medium;
        font-size: 24px;
        letter-spacing: normal;
      }
    }

    &-help {
      text-align: center;
    }

    &-footer {
      display: flex;
      column-gap: 16px;

      @include phone {
        flex-direction: column-reverse;
        padding: 0;
        row-gap: 8px;
      }
    }

    &-button {
      width: 50%;

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
        width: 100%;
      }
    }
  }

  &__choosing-pincode {
    font-size: 16px;
    text-decoration: underline;
    color: $--white-05percent;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    color: $--white;

    @include phone {
      font-size: $--font-size-small;
    }
  }
}
</style>
