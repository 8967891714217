import { defineStore } from 'pinia';
import { shallowRef } from 'vue';

export const usePosterDetailsStore = defineStore('posterDetail', () => {
  const detailsOpen_ = shallowRef(null);
  const detailsOpen = shallowRef(new Set());
  // swiper
  const posterSlidesPerView = shallowRef(7);
  const channelSlidesPerView = shallowRef(6);
  const spaceBetween = shallowRef(10);

  return {
    detailsOpen,
    // swiper
    posterSlidesPerView,
    channelSlidesPerView,
    spaceBetween,
  };
});
