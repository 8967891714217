<template>
  <div
    class="v-otp-input"
    :class="{ 'v-otp-input--error': isError }"
  >
    <div class="v-otp-input__wrapper">
      <div
        v-for="(item, index) in length"
        :key="item"
        :ref="`input-wrapper_${index}`"
        class="v-otp-input__element-wrapper"
        :class="{
          'v-otp-input__element-wrapper--has-space': hasSpace(index),
          'v-otp-input__element-wrapper--field': isFilled(index),
          'v-otp-input__element-wrapper--focused': inputFocused === index,
        }"
      >
        <label for="">
          <input
            :ref="`input_${index}`"
            maxlength="1"
            type="text"
            :data-index="index"
            inputmode="numeric"
            class="v-otp-input__element"
            :class="{ 'v-otp-input__element--active': inputFocused === index && isFilled(index) }"
            :value="localValue[index]"
            @keydown="handleKeydown($event, index)"
            @paste="handlePaste"
            @focus="isFocused(index)"
            @blur="isFocused(null)"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { BACKSPACE_CODE, TAB_CODE, ARROW_LEFT_CODE, ARROW_RIGHT_CODE, KEY_CODES_NUMBER } from '@/constants/keyCodes';

export default {
  name: 'VOtpInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    name: { type: String, required: true },
    length: {
      type: [Number, String],
      default: 1,
    },
    rules: { type: [Function, String, Object, Array], default: '' },
  },
  emits: ['update:modelValue', 'submit'],
  data() {
    return {
      inputFocused: 0,
      localValue: [],
    };
  },
  computed: {
    classes() {
      return {
        'v-otp-input__element-wrapper--focused': this.isFocusedValue,
      };
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.localValue = value.split('');

        if (value.split('')?.length === this.length) {
          this.$emit('submit');
        }
      },
    },
  },
  mounted() {
    this.focusByIndex(0);
    this.setLocalValueByString(this.modelValue);
  },
  methods: {
    isFilled(index) {
      return Boolean(this.localValue[index]);
    },
    isFocused(index) {
      this.inputFocused = index;
    },
    hasSpace(index) {
      return index + 1 === Number(this.length) / 2;
    },
    handlePaste(event) {
      const clipData = event.clipboardData.getData('text');

      this.setLocalValueByString(clipData);
      event.preventDefault();
    },
    handleKeydown(event, index) {
      const { key } = event;
      if (KEY_CODES_NUMBER.includes(key)) {
        this.localValue[index] = key;
        this.focusByIndex(index + 1);
      } else if (key === BACKSPACE_CODE) {
        if (this.localValue[index]) {
          this.localValue[index] = null;
        } else {
          this.localValue[index - 1] = null;
          this.focusByIndex(index - 1);
        }
      }
      if (key === ARROW_LEFT_CODE) this.focusByIndex(index - 1);
      if (key === ARROW_RIGHT_CODE) this.focusByIndex(index + 1);

      if (key !== TAB_CODE) {
        if (key !== 'v' || !(event.ctrlKey || event.metaKey)) {
          event.preventDefault();
        }
        event.stopPropagation();
      }

      this.sendInput();
    },
    focusByIndex(index) {
      const input = this.$refs[`input_${index}`];

      if (input) {
        input[0].focus();
      }
    },
    setLocalValueByString(str) {
      const arrValue = str.replace(/[^\d]+/g, '').split('');
      this.localValue = arrValue;

      this.sendInput();
      this.focusByIndex(arrValue?.length);
    },
    sendInput() {
      this.$emit('update:modelValue', this.localValue.join(''));
    },
  },
};
</script>

<style lang="scss">
.v-otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #263138;
  border: 1px solid #26333c;
  border-radius: 12px;
  width: 335px;
  padding: 32px 10px;

  @include phone {
    width: 240px;
    padding: 25px 10px;
  }

  &__wrapper {
    display: flex;
    column-gap: 5px;
  }

  &--error {
    border-color: $--orange-100;
  }

  &__element-wrapper {
    display: block;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 18px;
      height: 2px;
      background-color: $--white-02percent;
      border-radius: 2px;
      position: absolute;
      top: 20px;
    }

    &--focused {
      &::after {
        background-color: $--white;
        animation: eye 1.5s infinite;
      }
    }

    &--field {
      border: none;

      &::after {
        content: '';
        height: 0;
      }
    }

    &--has-space {
      margin-right: 10px;
    }
  }

  .v-otp-input__element {
    width: 18px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 24px;
    color: $--white;
    padding: 0 1px;
    caret-color: transparent !important;

    &--active {
      animation: input-active 1.3s infinite;
    }
  }

  @keyframes input-active {
    from {
      color: $--white-02percent;
    }

    50% {
      color: $--white;
    }

    to {
      color: $--white-02percent;
    }
  }

  @keyframes eye {
    90% {
      background-color: $--white-02percent;
    }

    95% {
      background-color: $--white;
    }
  }
}
</style>
