import { alertService } from '@/services/alert';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // handle error, e.g. report to a service
    console.error(error);
    if (error.fatal) {
      return;
    }
    if (process.client) {
      alertService.error({ text: error.message, isIconCross: true, timeout: 15000 });
      clearError();
    }
  };

  // Also possible
  nuxtApp.hook('vue:error', (error, instance, info) => {
    // handle error, e.g. report to a service
    console.error(error);
  });
});
