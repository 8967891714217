import { toBoolean } from '@/utils/typehint';

export default new Map([
  [
    // sting | regex
    'login',
    {
      skip: true,
    },
  ],
  [
    'messages/pop',
    {
      // useApiData().fetch не запустит useAsyncData/refresh если вернуть false
      before: () => useUserStore().isAuthenticated,
    },
  ],
  [
    'maclogin',
    {
      skip: true,
    },
  ],
  [
    'logout',
    {
      skip: true,
    },
  ],
  [
    'favorites/add',
    {
      skip: true,
    },
  ],
  [
    'favorites/delete',
    {
      skip: true,
    },
  ],
  [
    'paused_media/add',
    {
      skip: true,
    },
  ],
  [
    'paused_media/delete',
    {
      skip: true,
    },
  ],
  [
    'profile/set',
    {
      skip: true,
    },
  ],
  [
    'profile/add',
    {
      skip: true,
    },
  ],
  [
    'profile/delete',
    {
      skip: true,
    },
  ],
  [
    'profile/change',
    {
      skip: true,
    },
  ],
  [
    // строка пути или regexp
    'channel/changes',
    {
      // не кешировать
      skip: true,
      // this.flushDeps перезапросит эти пути
      dependencies: ['customer/info', 'channel/list'],
      // действие после получения ответа, arg: raw ответ, ctx: useApi.ReCache
      async after(response) {
        if (!toBoolean(response.have_changes)) {
          return;
        }
        await this.flushDeps('channel/changes');
      },
    },
  ],
  [
    'channel/list',
    {
      // если протухает, следующий fetch обновит
      ttl: 60000,
    },
  ],
  [
    'channel/url',
    {
      skip: true,
    },
  ],
  [
    'channel/recordurl',
    {
      skip: true,
    },
  ],
  [
    /^http(s?):\/\/cinema\.[^\/]+?\/video\/[^\/]+?\/stream/i,
    {
      skip: true,
    },
  ],
  [
    'program/listcurrent',
    {
      ttl: 60000,
    },
  ],
  [
    'program/listall',
    {
      ttl: 7200000,
    },
  ],
  [
    'program/fullinfo',
    {
      ttl: 7200000,
    },
  ],
  [
    'authkey/renew',
    {
      skip: true,
    },
  ],
]);
