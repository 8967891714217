import DeviceDetector from 'device-detector-js';
const deviceDetector = new DeviceDetector();

export function getDeviceType(req) {
  const response = deviceDetector.parse(req);
  const device = response.device?.type;

  if (device === 'desktop') {
    return 'desktop';
  } else if (device === 'tablet') {
    return 'tablet';
  } else if (device === 'smartphone') {
    return 'mobile';
  } else {
    return 'unknown';
  }
}
