import { toBoolean } from '@/utils/typehint';
import { th } from 'date-fns/locale';

export const useCustomerInfoStore = defineStore('customerInfo', () => {
  const { data, fetch: fetchCustomer } = useApiData('customer/info', {
    default: () => ({}),
  });
  const customerInfo = computed(() => data.value || {});
  const mainProfile = computed(() => customerInfo.value?.smart_profiles?.find(p => toBoolean(p.is_main) || null));

  async function addProfile(params) {
    const _params = {
      ...{
        title: '',
        name: '',
        email: '',
        icon: '',
        showUnsubscribed: false,
        showHint: false,
        phone: [],
        ageRating: '18',
        timeLimit: 0,
      },
      ...params
    };
    const response = await useApi('profile/add', {
      query: {
        title: _params.title,
        name: _params.name,
        email: _params.email,
        icon: _params.icon,
        phone_number: _params.phone.slice(1),
        show_unsubscribed: _params.showUnsubscribed,
        show_hint: _params.showHint,
        age_rating: _params.ageRating,
        time_limit: _params.timeLimit,
      },
    });

    if (!response.error) {
      fetchCustomer(true);
    }
    
    return true;
  }

  async function deleteProfile(id) {
    await useApi('profile/delete', { query: { id } });
    fetchCustomer(true);
  }

  /**
   * Обновить данные профиля
   * @param {Object} params 
   * @returns 
   */
  async function changeProfile(params) {
    const _default = {
      showUnsubscribed: false,
      showHint: false,
    };

    return await useApi('profile/change', { query: { ..._default, ...params }, contentQueryParams: false });
  }

  /**
   * Изменить профил на другой
   * @param {Number} params.id
   * @param {String} params.pin
   * throws {Error}
   * @returns {Object}
   */
  async function setProfile({ id, pin }) {
    return await useApi('profile/set', { query: { id, pin }, dropCache: true });
  }


  /**
   * Обновить локальные данные профиля
   * @param {Object} data 
   */
  function updateLocalProfile(data) {
    const profile = customerInfo.value.smart_profiles.find(p => p.id === data.id);
    if (profile) {
      Object.assign(profile, data);
    }
  }

  /**
   * Обновить данные профиля в БД, локально и в localStorage
   * @param {Object} data 
   * @returns 
   */
  async function updateProfile(data) {    
    await changeProfile(data);    

    const userStore = useUserStore();

    // Если локальный профиль совпадает с текущим, то обновляем его и сохраняем в localStorage
    if (userStore.updateLocalCurrentProfile(data)) {
      userStore.saveUserToLocalStorage();
    }

    updateLocalProfile(data);

    return true;
  }

  return {
    customerInfo,
    mainProfile,
    //
    fetchCustomer,
    addProfile,
    deleteProfile,
    changeProfile,
    updateLocalProfile,
    updateProfile,
    setProfile,
  };
});
