import { clean as timerClean, stop as timerStop, add as timerAdd } from '@/services/timer';

export default defineNuxtPlugin(async () => {
  const settingsStore = useSettingsStore();
  const { settings } = storeToRefs(settingsStore);
  const channelsListStore = useChannelsListStore();
  const customerInfoStore = useCustomerInfoStore();

  /*
    Periodic updates
  */
  window.addEventListener('beforeunload', timerClean);
  watch(
    settings,
    (settings, s0, cleanup) => {
      cleanup(() => {
        timerStop(
          'settings:messageCheckInterval',
          'settings:getSettingsInterval',
          'settings:authkeyRenewInterval',
          'settings:channelChangesInterval',
          'settings:getCustomerInfoInterval'
        );
      });

      if (!!settings?.intervals) {
        timerAdd(
          'settings:messageCheckInterval',
          () => useApi('messages/pop'),
          settings.intervals.messageCheckInterval * 1000
        );
        timerAdd(
          'settings:getSettingsInterval',
          () => settingsStore.fetchSettings(true),
          settings.intervals.getSettingsInterval * 1000
        );
        timerAdd(
          'settings:authkeyRenewInterval',
          () => useApi('authkey/renew'),
          settings.intervals.authkeyRenewInterval * 1000
        );
        timerAdd(
          'settings:channelChangesInterval',
          () => channelsListStore.fetchChanges(true),
          settings.intervals.channelChangesInterval * 1000
        );
      }

      if (!!settings?.intervalsV3) {
        timerAdd(
          'settings:getCustomerInfoInterval',
          () => customerInfoStore.fetchCustomer(true),
          settings.intervalsV3.getCustomerInfoInterval * 1000
        );
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );
});
