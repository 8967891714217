<template>
  <h1 class="v-card-title">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'VCardTitle',
};
</script>

<style lang="scss">
.v-card-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: $--white;

  @include phone {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}
</style>
