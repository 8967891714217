import { computed, unref } from 'vue';
import { getDeviceType } from '@/composables/useUserAgent';

export function useMediaQueries() {
  const mediaQueries = computed(() => {
    if (process.server) {
      const deviceType = getDeviceType(useRequestHeaders(['user-agent'])['user-agent']);

      return {
        desktop: deviceType === 'desktop',
        desktopMin: deviceType === 'desktop',
        tablet: deviceType === 'tablet',
        phone: deviceType === 'phone',
      };
    }

    return {
      desktop: unref(useMediaQuery('(min-width: 1025px)')),
      desktopMin: unref(useMediaQuery('(min-width: 1025px) and (max-width: 1200px)')),
      tablet: unref(useMediaQuery('(min-width: 481px) and (max-width: 1024px)')),
      phone: unref(useMediaQuery('(max-width: 480px)')),
    };
  });

  return mediaQueries;
}
