export const PHONE_MASK = [
  '+',
  '7',
  ' ',
  '(',
  /[1,2,3,4,5,6,9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const TEXT_MASK_CONFIG_DEFAULT = {
  guide: false,
  placeholderChar: '#',
};

export const TEXT_MASK_AVAILABLE_INPUT_TYPES = ['text', 'tel', 'url', 'password', 'search'];
