import 'floating-vue/dist/style.css';
import FloatingVue from 'floating-vue';

const options = {
  themes: {
    tooltip: {
      $extend: 'base-tooltip',
      $resetCss: false,
      triggers: ['hover', 'focus'],
      autoHide: true,
      placement: 'left',
    },
    'base-tooltip': {
      $resetCss: true,
      triggers: ['hover'],
      autoHide: true,
      placement: 'left',
    },
  },
};

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(FloatingVue, options);
});
