<template>
  <div class="search_bar">
    <input
      class="search_bar__input"
      placeholder="Фильм, Сериал, Канал, Передача, Актёр"
      @input="do_search"
      @keydown.enter="startSearch"
      v-focus
    />
    <Icon
      :icon="'pins/pin_close'"
      class="search_bar__close_button pointer"
      @click="closeSearch"
    />

<!--    для вывода необходима система рейтингов-->
<!--    PS: пока выводится только на главной странице-->
<!--    <div v-if="searchResults?.length" class="search_bar__results">-->
<!--      <ul>-->
<!--        <li v-for="item in searchResults.slice(0, 20)" :key="item.channel_id || item.film_id">-->
<!--          {{ item.program_name || item.name }}-->
<!--&lt;!&ndash;          добавить:&ndash;&gt;-->
<!--&lt;!&ndash;          открытие контента по клику&ndash;&gt;-->
<!--&lt;!&ndash;          авто скрытие при клике на пустую область&ndash;&gt;-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import doSearch from '@/store/fetchSearch';
import Icon from '@/components/common/icons/icon.vue';

const router = useRouter();
const emit = defineEmits();
const searchDone = shallowRef(false);

const closeSearch = () => {
  if (searchDone.value) router.go(-1); // router.back();
  emit('close-search');
};

const searchResults = shallowRef([]);
const do_search = async ({ target }) => searchResults.value = await doSearch(target.value);
const startSearch = ({ target }) => {
  searchDone.value = true;
  navigateTo({ name: 'search-name', params: { name: target.value } });
};

onMounted(() => {
  document.querySelector('.search_bar__input').focus();
});
</script>
