<template>
  <Spinner>
    <ProfileList
      :is-add-new-profile="true"
      :active-profile="propsProfileList.active"
      :control-activity="false"
      @on:set="propsProfileList.onSet"
      @on:add="propsProfileList.onAdd"
    />
  </Spinner>

  <ProfilePinCodeInstallDialog
    v-if="propsPinCodeInstallDialog.visible"
    :profile-name="propsPinCodeInstallDialog.title"
    @set-pin="propsPinCodeInstallDialog.onSetPin"
    @close="propsPinCodeInstallDialog.onClose"
  />

  <ProfilePinCodeChoosingDialog
    v-if="propsChoosingDialog.visible"
    :profile-name="propsChoosingDialog.title"
    @choosing="propsChoosingDialog.onChoosing"
    @close="propsChoosingDialog.onClose"
  />

  <ProfileCreateDialog
    :visible="propsProfileDialog.visible"
    :data="propsProfileDialog.data"
    @on:update-profile="propsProfileDialog.onCreateProfile"
    @on:visible="propsProfileDialog.onClose"
  />
</template>

<script setup>
import { alertService } from '@/services/alert';
import Spinner from '@/components/ui/Spinner.vue';
// Модальное окно. Можно установить пин код для профиля
import ProfilePinCodeInstallDialog from '@/components/Profile/PinCodeInstallDialog.vue';
// Вводи пин кода для перехода в основной
import ProfilePinCodeChoosingDialog from '@/components/Profile/ChoosingDialog.vue';
// Модальное окно. Создание профиля
import ProfileCreateDialog from '@/components/Profile/CreateDialog.vue';
import ProfileList from '@/components/Profile/ProfileList.vue';

const userStore = useUserStore();
const customerInfoStore = useCustomerInfoStore();
const { user } = userStore;

const propsProfileList = ref({
  active: ref(user.current_profile),
  onSet: profile => {
    if (profile.is_main) {
      propsChoosingDialog.value.set(propsProfileList.value.active, profile);
      propsChoosingDialog.value.show(true);
    } else {    
      if (user.current_profile.hide_set_pin) {
        setProfile(profile);
      } else {        
        propsPinCodeInstallDialog.value.set(profile);
        propsPinCodeInstallDialog.value.show(true, profile);
      }      
    }
  },
  onAdd: () => {
    propsProfileDialog.value.show(true);
  },
});

const propsProfileDialog = ref({
  visible: false,
  data: {},
  show(value) {
    propsProfileDialog.value.visible = value;
  },
  onCreateProfile() {
    console.log('Create profile');
    propsProfileDialog.value.show(false);
  },
  onClose() {
    propsProfileDialog.value.show(false);
  },
});

const setProfile = async (profile, pin) => {
  const id = profile.id;

  try {
    const response = await customerInfoStore.setProfile({ id, pin });

    userStore.changeCurrentProfile(response?.current_profile);
    customerInfoStore.fetchCustomer(true);

    propsProfileList.value.active = profile;
  } catch (e) {
    alertService.error({ text: e.message, isIconCross: true, timeout: 4500 });
    return false;
  }

  return true;
};

const propsChoosingDialog = ref({
  visible: false,
  profileActive: {},
  profileNext: {},
  title: computed(() => propsChoosingDialog.value.profileNext?.title),
  set(profileActive, profileNext) {
    Object.assign(propsChoosingDialog.value, {
      profileActive,
      profileNext,
    });
  },
  show(value) {
    propsChoosingDialog.value.visible = value;
  },
  onClose() {
    propsChoosingDialog.value.show(false);
  },
  onChoosing: async pin => {
    if (setProfile(propsChoosingDialog.value.profileNext, pin)) {
      propsChoosingDialog.value.show(false);
    }
  },
});

const propsPinCodeInstallDialog = ref({
  visible: false,
  title: ref(''),
  profileNext: {},
  set(profile) {
    Object.assign(propsPinCodeInstallDialog.value, {
      title: profile.title,
      profileNext: profile,
    });
  },
  show(value) {
    propsPinCodeInstallDialog.value.visible = value;
  },
  onClose() {
    propsPinCodeInstallDialog.value.show(false);
  },  
  onSetPin: async pin => {
    if (setProfile(propsPinCodeInstallDialog.value.profileNext, pin)) {
      propsPinCodeInstallDialog.value.show(false);
    }
  },
});
</script>
