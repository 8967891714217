<template>
  <div class="v-swiper">
    <div class="v-swiper__fade-right" />
    <div class="v-swiper__fade-left" />

    <swiper
      v-bind="{ ...swiperConfig, ...$attrs }"
      :modules="modules"
    >
      <swiper-slide
        v-for="slide in slides"
        :key="slide.id"
        v-bind="swiperSlideOptions"
      >
        <slot :slide="slide" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Mousewheel } from 'swiper';
import { useMediaQueries } from '@/composables/useMediaQueries';
const mediaQueries = useMediaQueries();
// import 'swiper/swiper-bundle.css'

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
  options: {
    type: Object,
    default: () => {
      return {
        swiperOptions: {},
        slideOptions: {},
      };
    },
  },
});
const modules = ref([Mousewheel]);

const offsetSwiper = computed(() => {
  return mediaQueries.value.phone ? 5 : 40;
});

const swiperConfig = computed(() => {
  const defaultConfig = {
    slidesPerView: 'auto',
    direction: 'horizontal',
    // slidesOffsetAfter: offsetSwiper.value,
    // slidesOffsetBefore: offsetSwiper.value,
    tag: 'div',
  };

  const { swiperOptions } = props.options;

  return {
    ...defaultConfig,
    ...swiperOptions,
  };
});

const swiperSlideOptions = computed(() => {
  const { slideOptions } = props.options;
  return slideOptions;
});
</script>

<script>
export default {
  name: 'VSwiper',
  inheritAttrs: false,
};
</script>

<style lang="scss">
.v-swiper {
  position: relative;

  .swiper {
    @include phone {
      margin: 0 -5px 0 -5px;
    }
  }

  .swiper-slide {
    width: auto;
    margin-right: 8px;
  }

  &__possible-subscription {
    margin-top: 32px;
  }

  &__fade-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -40px;
    width: 40px;
    background: linear-gradient(270deg, #111415 2.5%, rgba(17, 20, 21, 0) 100%);
    z-index: $--z-index-min;

    @include phone {
      right: -5px;
      width: 10px;
    }
  }

  &__fade-left {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -40px;
    width: 40px;
    background: linear-gradient(270deg, #111415 2.5%, rgba(17, 20, 21, 0) 100%);
    transform: rotate(180deg);
    z-index: $--z-index-min;

    @include phone {
      left: -5px;
      width: 10px;
    }
  }
}
</style>
