<template>
  <v-card class="registration-send-phone">
    <v-card-header class="registration-send-phone__header">
      <template #title>
        Добро пожаловать!
      </template>
      <template
        v-if="isRegistration"
        #subtitle
      >
        Зарегистрируйтесь на платформе MOOVI, <br v-if="mq.phone">
        чтобы всегда иметь доступ к качественному<br v-if="mq.phone">
        контенту
      </template>
    </v-card-header>
    <validation-observer v-slot="{ errors, handleSubmit }">
      <v-form
        class="registration-send-phone__form"
        @submit.prevent="handleSubmit(sendPhone)"
      >
        <template #default>
          <div class="registration-send-phone__form-main">
            <v-form-row>
              <v-form-field
                label="Телефон"
                class="registration-send-phone__form-field"
                :error-message="errors.phone"
              >
                <v-input-phone
                  ref="input-send-phone"
                  v-model.trim="phone"
                  autofocus
                  type="text"
                  :is-error="errors.phone"
                  name="phone"
                  rules="required"
                />
              </v-form-field>
            </v-form-row>
            <v-form-row v-if="isRegistration">
              <v-form-field
                class="registration-send-phone__form-field"
                :error-message="errors.approved"
              >
                <v-checkbox
                  v-model:checked="isApproved"
                  name="approved"
                  rules="required"
                  :is-error="errors.approved"
                  class="registration-send-phone__form-checkbox-wrapper"
                >
                  <template #label>
                    <div class="registration-send-phone__form-approved">
                      Я соглашаюсь с <a href="#">Пользовательским соглашением сервиса Moovi</a>
                    </div>
                  </template>
                </v-checkbox>
              </v-form-field>
            </v-form-row>
          </div>
        </template>
        <template #footer>
          <slot name="actions" />
        </template>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { sendMobileRegister } from '@/api/sendMobileRegister';
import VCard from '@/components/common/VCard.vue';
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VInputPhone from '@/components/common/VInputPhone.vue';
import VCheckbox from '@/components/common/VCheckbox.vue';
import VCardHeader from '@/components/common/VCardHeader.vue';
import { alertService } from '@/services/alert';
import { Form as ValidationObserver } from 'vee-validate';

export default {
  name: 'RegistrationSendPhone',
  components: {
    VCard,
    VForm,
    VFormRow,
    VFormField,
    VInputPhone,
    ValidationObserver,
    VCheckbox,
    VCardHeader,
  },
  inject: ['mq'],
  props: {
    isRegistration: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['validated', 'startValidate'],
  data() {
    return {
      phone: '',
      isApproved: false,
    };
  },
  methods: {
    sendPhone() {
      this.phone = this.phone.replaceAll(/[^0-9]/g, '').slice(1);
      if (this.phone?.length === 10) {
        this.$emit('startValidate');
        sendMobileRegister(`/mobile/register?askconfirm=1&phone_number=${this.phone}`).then(res => {
          if (res.data.error) {
            alertService.error({ text: 'Введите корректный номер', isIconCross: true, timeout: 4500 });
            this.$emit('startValidate', false);
          } else {
            this.$emit('validated', this.phone);
          }
        });
      } else {
        alertService.error({
          text: 'Введите номер полностью',
          isIconCross: true,
          timeout: 4500,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.registration-send-phone {
  width: 100%;
  background-color: #1c2227;
  border-radius: $--border-radius-large;
  padding-top: 90px;

  @include phone {
    padding-top: 80px;
  }

  &__header {
    margin-bottom: 48px;

    @include phone {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: $--font-size-larger-title;
    font-weight: $--font-weight-bold;
    margin-bottom: 24px;

    @include phone {
      font-size: $--font-size-subtitle-phone;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: $--light-gray-300;
    font-size: $--font-size-subtitle-phone;
    line-height: 32px;

    @include phone {
      font-size: $--font-size-small;
      line-height: 16px;
    }
  }

  &__form {
    &-main {
      margin-bottom: 40px;

      @include phone {
        margin-bottom: 55px;
      }
    }

    &-checkbox-wrapper {
      display: flex;
      align-items: center;
    }

    &-field {
      &:last-child {
        margin-bottom: 20px;

        @include phone {
          margin-bottom: 16px;
        }
      }
    }

    &-approved {
      color: $--white;
      font-size: $--font-size-base;

      a {
        color: $--white;
      }
    }

    &-buttons-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 64px;
      row-gap: 16px;

      @include phone {
        padding: 0;
        row-gap: 8px;
      }
    }

    &-button {
      width: 100%;

      &:last-child {
        color: $--light-blue;
        font-weight: $--font-weight-regular;
        font-size: $--font-size-button;
      }

      @include phone {
        font-size: $--font-size-subtitle-phone;
        height: 56px;
      }
    }
  }
}
</style>
