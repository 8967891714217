import { defineStore } from 'pinia';
// import { loadStorageItem, saveStorageItem } from '@/services/storage';

/**
 * @deprecated
 */
export const useGeneralStore = defineStore({
  id: 'generalState',
  state: () => {
    return {
      // isEmergencyMode: JSON.parse(loadStorageItem('isEmergencyMode')) || false,
      isEmergencyMode: false,
    };
  },
  actions: {
    changeIsEmergencyMode(value) {
      this.isEmergencyMode = value;
      // saveStorageItem('isEmergencyMode', value);
    },
  },
});
