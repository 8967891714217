<template>
  <div></div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { commonBreakPoints } from '@/components/ui/breakPoints';

const { posterSlidesPerView, channelSlidesPerView, spaceBetween } = storeToRefs(usePosterDetailsStore());

const updateSizes = () => {
  if(process.server) return 7;
  const windowWidth = window.innerWidth;
  if (windowWidth < 600) spaceBetween.value = 5;
  const _spaceBetween = commonBreakPoints[windowWidth]?.spaceBetween || 10;

  // posters
  const posterWidth =
    windowWidth <= 720 ? 125 :
    windowWidth <= 1024 ? 146 :
    windowWidth <= 1280 ? 176 :
    windowWidth <= 1440 ? 176 :
    windowWidth <= 1920 ? 263 : 300;
  posterSlidesPerView.value = windowWidth / (posterWidth + _spaceBetween * 1.5);

  // channels and programs
  const channelWidth =
    windowWidth <= 720 ? 160 :
    windowWidth <= 1024 ? 178 :
    windowWidth <= 1280 ? 216 :
    windowWidth <= 1440 ? 216 :
    windowWidth <= 1920 ? 324 : 352;
  channelSlidesPerView.value = windowWidth / (channelWidth + _spaceBetween * 1.5);
};

let resizeTimer = null;
const onWindowResize = () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(updateSizes, 300);
};

const instance = getCurrentInstance();
const app = instance.appContext.app;
onMounted(() => {
  app.directive('focus', focusDirective);
  if(process.server) return null;
  updateSizes();
  window.addEventListener('resize', onWindowResize);
});

onUnmounted(() => {
  app.directive('focus', null);
  if(process.server) return null;
  window.removeEventListener('resize', onWindowResize);
  if (resizeTimer) {
    clearTimeout(resizeTimer);
    resizeTimer = null;
  }
});

const focusDirective = {
  mounted(el) {
    el.focus();
  }
};
</script>
