<template>
  <section class="profiles-section">
    <VSwiper
      :options="{ swiperOptions: { wrapperTag: 'ul' }, slideOptions: { tag: 'li' } }"
      :slides="slides"
      :mousewheel="{ forceToAxis: true }"
      class="profiles-section__list"
    >
      <template #default="{ slide }">
        <div
          class="profiles-section__item"
          :class="{
            'profiles-section__item--active': slide.id === activeProfileId,
            'profiles-section__item--empty': slide.type === 'empty',
          }"
        >
          <VEmptyCard
            v-if="slide.type === 'empty'"
            tiny
            has-not-popover
            @click="add"
          />
          <button
            v-else
            class="profiles-section__item-button"
            @click="choice(slide)"
          >
            <ProfileAvatar
              class="profiles-section__item-avatar"
              size="big"
              :icon="slide.icon"
            />
            <span class="profiles-section__item-text">{{ slide.title }}</span>
          </button>
        </div>
      </template>
    </VSwiper>
  </section>
</template>

<script setup>
import ProfileAvatar from '@/components/Profile/Avatar.vue';
import VEmptyCard from '@/components/common/VEmptyCard.vue';
import VSwiper from '@/components/common/VSwiper.vue';
import { MAIN_PROFILE_VALUES } from '@/constants/common';

const props = defineProps({
  activeProfile: {
    type: Object,
    default: null,
  },
  controlActivity: {
    type: Boolean,
    default: true,
  },
  isAddNewProfile: {
    type: Boolean,
    default: false,
  },
});

const customerInfoStore = useCustomerInfoStore();
const userStore = useUserStore();
const { customerInfo } = storeToRefs(customerInfoStore);
const { user } = storeToRefs(userStore);
const activeProfileId = ref(props.activeProfile.id);

watchEffect(() => {
  activeProfileId.value = props.activeProfile.id;
});

const isProfileMain = profile => {
  return MAIN_PROFILE_VALUES[profile.is_main];
};

const getUserProfiles = () => {
  return customerInfo.value.smart_profiles?.filter(profile => {
    return isProfileMain(profile) || profile.id === user.value.current_profile.id;
  });
};

const profiles = computed(() => {
  return (isProfileMain(user.value.current_profile) ? customerInfo.value?.smart_profiles : getUserProfiles()) || [];
});

const emits = defineEmits(['on:set', 'on:add']);

const slides = computed(() => {
  return props.isAddNewProfile ? [...profiles.value, { type: 'empty' }] : profiles.value;
});

const choice = profile => {
  if (activeProfileId.value === profile.id) {
    return;
  }

  if (props.controlActivity) {
    activeProfileId.value = profile.id;
  }

  emits('on:set', { ...profile });
};

const add = () => {
  emits('on:add');
};
</script>

<style lang="scss">
.profiles-section {
  $this: &;

  &__list {
    display: flex;
    align-items: center;
    column-gap: 40px;

    .swiper-wrapper {
      align-items: center;
    }
  }

  &__item-button {
    padding: 13px 24px;
  }

  &__item {
    height: 52px;
    border-radius: 12px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    transition: $--theme-transition;
    opacity: 0.7;

    @include phone {
      width: 209px;
      height: 80px;
      padding: 16px;
    }

    &:hover {
      background-color: #364a58;
      opacity: 1;
    }

    &--empty {
      all: unset;
    }

    &--empty.profiles-section__item:hover {
      background-color: transparent;
    }

    &--active {
      background-color: #263138;
      border-radius: 0 18px 18px 0;
      opacity: 1;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: $--orange;
        border-radius: 100px;
      }
    }

    &-button {
      max-width: 260px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-avatar {
      margin-right: 13px;
    }

    &-text {
      color: $--white;
      font-size: $--font-size-button;
      font-weight: $--font-weight-medium;
      text-overflow: ellipsis;
      overflow: hidden;

      @include phone {
        font-size: $--font-size-button;
      }
    }

    &-edit {
      position: absolute;
      top: 58px;
      left: 80px;
      width: 32px;
      height: 32px;
      background-color: $--light-gray-200;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &::after {
        content: '';
        width: 16px;
        height: 16px;
        background: url(/assets/icons/edit.svg) center no-repeat;
        background-size: contain;
      }

      &:hover {
        background-color: $--light-gray-100;
      }
    }
  }
}
</style>
