<template>
  <button
    class="profile-avatar"
    :class="classes"
  >
    <img
      :src="icon"
      class="profile-avatar__img"
      alt="avatar"
    />
  </button>
</template>

<script>
export default {
  name: 'ProfileAvatar',
  props: {
    size: { type: String, default: '' },
    checked: { type: Boolean, default: false },
    isButton: { type: Boolean, default: false },
    mark: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
  computed: {
    classes() {
      const { size, checked, isButton, mark } = this;
      return {
        [`profile-avatar--size-${size}`]: Boolean(size),
        [`profile-avatar--checked`]: Boolean(checked),
        [`profile-avatar--is-button`]: Boolean(isButton),
        [`profile-avatar--mark_${mark}`]: Boolean(mark),
      };
    },
    // backgroundAvatar() {
    //   return `${this.avatar}`;
    // },
  },
};
</script>

<style lang="scss">
.profile-avatar {
  width: 40px;
  height: 40px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  object-fit: cover;
  display: flex;

  &::before {
    content: '';
    border: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    transition: 0.3s;
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.3s;
  }
  &--is-button {
    &:hover {
      &::before {
        border-color: #334756;
      }
    }
  }

  &--mark {
    &_edit,
    &_check {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        bottom: -7px;
        right: -10px;
      }
    }

    &_check {
      &::after {
        background-image: url(/assets/icons/checkBlack.svg);
        background-color: $--green;
      }
    }

    &_edit {
      &::after {
        background-image: url(/assets/icons/edit.svg);
        background-color: $--light-gray-200;
      }
    }
  }

  &--size-big {
    min-width: 40px;
    height: 40px;
  }

  &--checked {
    &::before {
      border-color: $--white;
    }
  }
}
</style>
