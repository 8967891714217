<template>
  <div class="form-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VFormRow',
};
</script>

<style lang="scss">
.form-row {
  &:not(:last-of-type) {
    margin-bottom: 32px;

    @include phone {
      margin-bottom: 20px;
    }
  }

  position: relative;

  &--flex {
    display: flex;
    justify-content: space-between;
  }
}
</style>
