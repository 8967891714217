<template>
  <div class="flex">
    <div
      class="seasons_modal__image pointer"
      @click="navigateTo({ name: 'vod-name', params: { name: episode.film_id || episode.id } })"
    >
      <img
        ref="defaultImage"
        :src="episode.images.poster_604_348"
        @error="putErrorImage"
        :alt="episode?.program_name || episode?.serial_title || episode?.title || episode?.name"
      >
      <div class="programProgressBack">
        <div
          class="programProgressFront"
          :style="{ width: progressPercent + '%' }"
        />
      </div>
    </div>

    <div>
      <div class="seasons_modal__title">
        {{ episode.serial_title }}
      </div>
      <div class="seasons_modal__program">
        {{ episode.title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { getDefaultImage } from '@/utils/ui/getDefaultImage';

const props = defineProps({
  episode: {
    type: Object,
    default: () => ({}),
  },
  episodeIndex: {
    type: Number,
    default: 0
  },
  currentSeason: {
    type: Number,
    default: 0
  }
});

const pausedStore = usePausedListStore();
const progressPercent = shallowRef(0);

async function getProgressPercent() {
  const { episode } = props;
  const vodIsPaused = pausedStore.vodInPaused(episode.id);
  if (!vodIsPaused) return progressPercent.value = 0;
  return progressPercent.value = vodIsPaused / episode.duration * 100;
}

watchEffect(async () => await getProgressPercent());

function putErrorImage(event) {
  event.target.src = getDefaultImage('poster_604_348');
}
</script>
