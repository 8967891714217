<template>
  <v-dialog
    v-model:visible="localVisible"
    class="where-find-login-password-dialog"
    size="large"
  >
    <template #header>
      <v-card-title class="where-find-login-password-dialog__title">
        Где найти логин и пароль
      </v-card-title>
    </template>
    <p class="where-find-login-password-dialog__text">
      Чтобы получить логин/пароль вам необходимо пройти авторизацию любым доступным для вас способом. Если у вас уже
      имеется логин/пароль, но вы его забыли либо потеряли - обратитесь в службу поддержки провайдера, предоставляющего
      вам услугу данного сервиса.
    </p>
    <div class="where-find-login-password-dialog__buttons-wrapper">
      <v-button
        type="submit"
        class="where-find-login-password-dialog__button"
        primary
        @click="localVisible = false"
      >
        Спасибо
      </v-button>
    </div>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue';
import VButton from '@/components/common/VButton.vue';
import VCardTitle from '@/components/common/VCardTitle.vue';

export default {
  name: 'WhereFindLoginPasswordDialog',
  components: {
    VDialog,
    VButton,
    VCardTitle,
  },
  props: {
    visible: { type: Boolean, default: false },
  },
  emits: ['update:visible'],
  computed: {
    localVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
  },
};
</script>

<style lang="scss">
.where-find-login-password-dialog__title {
  padding: 56px 40px 24px;
  text-align: center;
}

.where-find-login-password-dialog__text {
  padding: 0 0 80px;
  color: get-theme-for($text, 'primary');
  font-size: 18px;
}

.where-find-login-password-dialog__buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include phone {
    padding-bottom: 32px;
  }
}

.where-find-login-password-dialog__button {
  text-align: center;
  width: 224px;
}
</style>
