<template>
  <!-- <teleport v-if="componentLoaded" to="#dialog-root"> -->
  <teleport :to="teleportTo">
    <div>
      <fade-transition>
        <div
          v-if="visible"
          class="dialog-overlay"
          :style="`background-color: ${overlayColor}`"
          data-testid="dialog-overlay"
          @click="close"
        />
      </fade-transition>

      <slide-transition>
        <div
          v-if="visible"
          v-scroll-lock="visible"
          class="dialog"
          :aria-label="title"
          role="dialog"
          @click="close"
        >
          <div class="dialog__document-wrapper">
            <div
              class="dialog__document"
              :class="classes"
              role="document"
              @click.stop
            >
              <button
                v-if="closable"
                class="dialog__close"
                type="button"
                aria-label="Close"
                @click="close"
              >
                <v-icon
                  name="cross"
                  size="24"
                  title="Закрыть"
                />
              </button>
              <slot name="header">
                <!-- <h1 v-if="title" class="dialog__title">{{ title }}</h1> -->
              </slot>
              <div class="dialog__content-wrapper">
                <slot
                  v-if="$slots.content"
                  name="content"
                />
                <div
                  v-else
                  class="dialog__content"
                >
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-transition>
    </div>
  </teleport>
</template>

<script setup>
import vScrollLock from '@/directives/ScrollLock';
import SlideTransition from '@/components/transitions/SlideTransition.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';
import VIcon from '@/components/common/VIcon.vue';

const props = defineProps({
  overlayColor: {
    type: String,
    default: 'rgba(17, 22, 26, 0.9);',
  },
  visible: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'normal',
    // extra-large - 880px, large-plus - 670px, large - 560px, normal - 456px , small - 363px, tiny - 345px
    validator: size => ['normal', 'small', 'large', 'large-plus', 'extra-large', 'vod-player', 'tiny'].includes(size),
  },
  title: {
    type: String,
    default: null,
  },
  closable: Boolean,
  teleportTo: {
    default: 'body',
  },
});
const emit = defineEmits(['close', 'update:visible']);
// const backgroundAvatar = computed(() => `background-image: url(${props.avatar})`);
const modelVisible = shallowRef(props.visible);

const classes = shallowRef({
  [`dialog__document--size_${props.size}`]: Boolean(props.size),
  ['dialog__document__seasons_modal']: props.teleportTo !== 'body',
});

const close = () => {
  modelVisible.value = false;
  emit('update:visible', false);
  emit('close');
};

const handleEscape = event => {
  if (event.key === 'Escape') close();
};
onMounted(() => {
  document.addEventListener('keydown', handleEscape);
});
onUnmounted(() => {
  document.removeEventListener('keydown', handleEscape);
});
</script>

<style lang="scss">
$document-padding: 48px;
$--z-index-max: 9000;
$--z-index-negative: -1;
$--z-index-high: 1000;
$--darken-black: #282829;

@mixin dialogSize($size) {
  @media screen and (max-height: $size) {
    top: 40px;
    transform: none;
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $--z-index-high;
}

.dialog {
  $block: &;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: $--z-index-high;

  &__document-wrapper {
    padding: 5vh 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }

  &__content {
    padding: 0 48px 80px;

    @include phone {
      padding: 0 16px 0;
    }
  }

  &__document {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: get-theme-for($background, 'secondary');
    overflow-x: hidden;
    width: 456px;
    border-radius: $--border-radius-large;
    transform: translateY(-10%);

    &--size {
      &_small,
      &_tiny,
      &_large,
      &_large-plus,
      &_extra-large {
        width: 100%;
      }

      &_small {
        width: 363px;
        @include dialogSize(363px);
      }

      &_tiny {
        width: 345px;
        @include dialogSize(345px);
      }

      &_large {
        max-width: 560px;
        @include dialogSize(560px);
      }

      &_large-plus {
        max-width: 670px;
        @include dialogSize(670px);
      }

      &_extra-large {
        max-width: 880px;
        @include dialogSize(880px);
      }

      &_vod-player {
        width: 100%;
      }
    }

    &__seasons_modal {
      position: absolute;
      right: 2.5%;
      bottom: 15%;
    }
  }

  @media screen and (max-height: 1000px) {
    &__document {
      top: 40px;
      transform: none;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
    font-size: $--font-size-larger-title;
    color: get-theme-for($text, 'primary');

    @include phone {
      font-size: $--font-size-subtitle-phone;
    }
  }

  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
    color: $--white-05percent;

    &:hover {
      color: $--white;
    }

    @include phone {
      top: 16px;
      right: 16px;
    }
  }
}
</style>
