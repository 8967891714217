export const useSettingsStore = defineStore('settings', () => {
  const { data: settings, fetch: fetchSettings } = useApiData('settings/get', {
    default: () => {
      return {}
    },
    transform: d => d.settings,
  });
  const vodUrl = computed(() => settings.value?.CLIENT_SETTINGS?.vod_url);

  return {
    settings,
    vodUrl,
    //
    fetchSettings,
  };
});
