<template>
  <VDialog
    :visible="true"
    size="large"
    @close="$emit('close')"
  >
    <template #content>
      <div class="profile-pin-code-install">
        <header class="profile-pin-code-install__header">
          <p class="profile-pin-code-install__subtitle">
            Вы перешли в профиль «{{ profileName }}». Ваш PIN-код для возврата в Основной профиль – <span>0000</span>.
            Поменять PIN-код вы можете в настройках Основного профиля.
          </p>
        </header>
        <ValidationObserver v-slot="{ errors, handleSubmit }">
          <VForm
            class="profile-pin-code-install__form"
            @submit.prevent="handleSubmit(installCode)"
          >
            <template #default>
              <div class="profile-pin-code-install__form-main">
                <VFormRow class="profile-pin-code-install__form-row">
                  <VButton
                    type="submit"
                    primary
                    class="profile-pin-code-install__form-button"
                  >
                    Продолжить
                  </VButton>
                </VFormRow>
              </div>
            </template>
            <template #footer>
              <footer class="profile-pin-code-install__form-footer">
                <VCheckbox
                  type="checkbox"
                  :checked="hideSetPin"
                  name="approved"
                  class="profile-pin-code-install__checkbox"
                  @update:checked="checked => (hideSetPin = checked)"
                >
                  <template #label> Больше не показывать это сообщение </template>
                </VCheckbox>
              </footer>
            </template>
          </VForm>
        </ValidationObserver>
      </div>
    </template>
  </VDialog>
</template>

<script setup>
import { Form as ValidationObserver } from 'vee-validate';
import VForm from '@/components/form/VForm.vue';
import VFormRow from '@/components/form/VFormRow.vue';
import VFormField from '@/components/form/VFormField.vue';
import VCheckbox from '@/components/common/VCheckbox.vue';
import VButton from '@/components/common/VButton.vue';
import VDialog from '@/components/common/VDialog.vue';
import VOtpInput from '@/components/common/VOtpInput.vue';
const { user } = storeToRefs(useUserStore());

defineProps({
  phone: {
    type: String,
    default: '',
  },
  profileName: { type: String, default: '' },
  visible: { type: Boolean, default: false },
});
const hideSetPin = shallowRef(user.hide_set_pin || false);
const code = shallowRef('0000');
const emit = defineEmits(['submitted', 'back', 'update:visible', 'set-pin', 'close']);

function installCode() {
  const _code = code.value.replace(/\s/g, '');

  if (!_code || !String(_code).trim().length) {
    return;
  }

  emit('set-pin', _code, hideSetPin.value);
}

watch(hideSetPin, newValue => {
  localStorage.setItem('hide_set_pin', newValue.toString());
  user.value = {
    ...user.value,
    current_profile: {
      ...user.value.current_profile,
      hide_set_pin: newValue,
    },
  };
});

onMounted(() => {});
</script>
