const timers = new Map();
const defaultInterval = 100;

/**
 * start new timer
 * @param {String} name 
 * @param {Function} fn 
 * @param {Number} interval 
 * @returns 
 */
function add(name, fn, interval = defaultInterval) {
  if (timers.has(name)) {
    return;
  }

  timers.set(name, [fn, setInterval(fn, interval)]);
}
/**
 * stop timer w/ name & clear, delete it
 * @param {String} name 
 * @returns 
 */
function stop(name) {
  if (!timers.has(name)) {
    return;
  }

  const [_, interval] = timers.get(name);

  clearInterval(interval);
  timers.delete(name);
}
/**
 * stop all timers
 */
function clean() {
  for (let key of timers.keys()) {
    stop(key);
  }
}
/**
 * restart timer (with name) using new intervar (in ms)
 * @param {String} name 
 * @param {Number} interval 
 * @returns 
 */
function restart(name, interval = defaultInterval) {
  if (!timers.has(name)) {
    return;
  }

  const [fn] = timers.get(name);

  stop(name);
  add(name, fn, interval);
}

export { add, stop, clean, restart };
